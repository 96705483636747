/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:da5f8950-69af-4920-9984-602137c75b55",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_VtkEovzQM",
    "aws_user_pools_web_client_id": "79llj5e6kkgmaj7hcg7uid31is",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://4223b5damfdkljhkc23yjqnpxu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wvqw2d67irb3fm4z76nh3xbrji",
    "aws_cloud_logic_custom": [
        {
            "name": "payzenCallback",
            "endpoint": "https://5yzefulas1.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "cliquezpostezweb2-20191019103137-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://cliquezpostezweb2-20191019103137-hostingbucket-prod.s3-website-eu-west-1.amazonaws.com",
    "aws_user_files_s3_bucket": "cliquezpostezdocuments-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "TransportLetterMapping-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "TransportLetterJRMCMonitor-prod",
            "region": "eu-west-1"
        },
        {
            "tableName": "BillMapping-prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;

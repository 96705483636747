import React, {createContext, useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
import {Helmet} from "react-helmet"

export const CanonicalLinkContext = createContext({})
export const CanonicalLinkProvider = ({children})=>{
  const {pathname } = useLocation()
  const [canonicalPath, setCanonicalPath] = useState(null)
  useEffect(()=>{
    setCanonicalPath(pathname)
  },[pathname])

  return <CanonicalLinkContext.Provider value={{ setCanonicalPath }}>
  {/*  <Helmet>*/}
  {/*  {canonicalPath && <link rel="canonical" href={`https://www.cliquezpostez.com${canonicalPath}`} />}*/}
  {/*</Helmet>*/}
    {children}
  </CanonicalLinkContext.Provider>
}

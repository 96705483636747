import {Badge, Button, Image, Nav, Navbar, OverlayTrigger, Tooltip} from "react-bootstrap"
import React, {useContext} from "react"
import {LinkContainer} from "react-router-bootstrap"
import {AuthContext} from "./components/AuthComponent"
import LoginModal, {LoginContext} from "./LoginModal"
import {BasketContext} from "./components/BasketComponent"
import hpCliquezPostezLogo from "./assets/images/hp-cliquez-postez-logo.svg"
import appBasketImg from "./assets/images/app-basket.svg"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons/faShoppingCart";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";

function CenterNavBar() {
  return (
    <Nav className="mr-2">
      <LinkContainer to="/a-propos">
        <Nav.Link href="/a-propos">Qui sommes-nous ?</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/lettre-recommandee-en-ligne">
        <Nav.Link href="/lettre-recommandee-en-ligne">Nos services</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/modele-lettre-recommandee/">
        <Nav.Link href="/modele-lettre-recommandee/">Nos modèles</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/faq">
        <Nav.Link href="/faq">Une question ?</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/tarif-lettre-recommandee">
        <Nav.Link href="/tarif-lettre-recommandee">Tarifs</Nav.Link>
      </LinkContainer>
    </Nav>
  )
}

function CreateLetterImageButton({className, size}) {
  const buttonSize = size || 20
  const svgSize = 2.8 * buttonSize / 5
  const padding = (size-svgSize)/2
  console.log(`buttonsize: ${buttonSize}`)
  return <OverlayTrigger
    placement="bottom"
    delay={{ show: 0, hide: 500 }}
    overlay={(props)=>MyTooltip({...props, title: "Envoyer un courrier"})}>
    <LinkContainer to="/createLetter" style={{ width: `${buttonSize}px`, height: `${buttonSize}px` }} activeClassName={className}>
      <Button variant="outline-light" className="createLetterImageButton round p-2" style={{
        position: "relative"
      }}
              aria-label="Envoyer un courrier"
      >
        <Image style={{
          width: `${svgSize}px`,
          height: `${svgSize}px`,
          position: "absolute",
          left: `${padding}px`,
          top: `${padding}px`
        }} src={appBasketImg} className="buttonSvg"/>
      </Button>
    </LinkContainer>
  </OverlayTrigger>
}

const DisconnectedNavBarContent = () => {
  const { showModal } = useContext(LoginContext)
  const openLogin = () => {
    showModal()
  }
  return (
    <>
      <div style={{display: "flex",
        flexDirection: "row",
        flexWrap: "no-wrap",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between"}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2" />
        <LinkContainer to="/" style={{flexGrow: "1"}}>
          <Navbar.Brand className="mr-1" href="/">
            <Image alt="Cliquez Postez" src={hpCliquezPostezLogo} fluid width="193px" height="23px"/>
          </Navbar.Brand>
        </LinkContainer>
        <div className="d-none d-lg-flex" style={{display: "flex",
          flexDirection: "row",
          flexWrap: "no-wrap",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: "1"
        }}>
          <Nav className="">
            <LinkContainer to="/">
              <Nav.Link href="/">Accueil</Nav.Link>
            </LinkContainer>
          </Nav>
          <CenterNavBar />
          {/*<Nav className="ml-auto">*/}
          <div className="d-flex align-items-center" style={{flexGrow: "1", justifyContent:"flex-end"}}>
            <div className="d-none d-lg-flex" style={{ position: "relative", width: "60px" }}>
              <CreateLetterImageButton size={40}/>
            </div>
            <div className="d-none d-md-block d-sm-block">
              <Button
                variant="outline-light"
                style={{
                  padding: ".33em .5em",
                  fontSize: "16pt",
                  fontFamily: "Gilroy-Semibold, sans-serif",
                }}
                onClick={openLogin}
              >Connexion
              </Button>
            </div>
          </div>
          {/*</Nav>*/}
        </div>
        <div style={{display: "flex",
          flexDirection: "horizontal",
          flexWrap: "no-wrap",
          alignItems: "center",
          justifyContent: "flex-end"
        }} className="d-xs-inline d-lg-none">
          <div className="mr-2" style={{ position: "relative" }}>
            <CreateLetterImageButton size={34} />
          </div>
          <div className="" onClick={openLogin}>
            <FontAwesomeIcon color="white" icon={faUser} />
          </div>
        </div>
      </div>
      <div className="d-xs-inline d-lg-none mr-2">
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Nav.Link href="/">Accueil</Nav.Link>
            </LinkContainer>
          </Nav>
          <CenterNavBar />
          {/*<Nav className="ml-auto">*/}
          <div className="d-flex align-items-center">
              <Button
                variant="outline-light"
                style={{
                  padding: ".33em .5em",
                  fontSize: "16pt",
                  fontFamily: "Gilroy-Semibold, sans-serif",
                }}
                onClick={openLogin}
              >Connexion
              </Button>
          </div>
          {/*</Nav>*/}
        </Navbar.Collapse>
      </div>


      <LoginModal />
    </>
  )
}

export const DisconnectedNavBar = (props) => {
  return (
    <Navbar className="hp-nav maxWidthContainer navbar-dark" expand="lg">
      <DisconnectedNavBarContent />
    </Navbar>
  )
}

export const DisconnectedNavBarBlue = (props) => {
  return (
    <Navbar variant="dark" expand="lg" className="appnavbar navbar-dark">
      <DisconnectedNavBarContent />
    </Navbar>
  )
}
export const MyTooltip = (props) => {
  return <Tooltip id="button-tooltip" {...props}>{props.title}</Tooltip>
}

export const ConnectNavBarContent = (props) => {
  const { user } = useContext(AuthContext)
  const { lettersInBasket } = useContext(BasketContext)
  const countBasket = lettersInBasket ? lettersInBasket.length : 0
  // console.log(`countBasket: ${countBasket}`)
  if (user) {
    return (
      <>
        <div style={{display: "flex",
          flexDirection: "row",
          flexWrap: "no-wrap",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between"}}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <LinkContainer to="/" style={{flexGrow: "1"}}>
            <Navbar.Brand className="mr-1" href="/">
              <Image alt="Cliquez Postez" src={hpCliquezPostezLogo} fluid width="193px" height="23px"/>
            </Navbar.Brand>
          </LinkContainer>
          <div className="d-flex align-items-center">
            <div className="d-xs-inline d-lg-none mr-2" style={{ position: "relative" }}>
              <CreateLetterImageButton size={34} />
            </div>
            <LinkContainer to="/dashboard" className="d-xs-inline d-lg-none mr-2" activeClassName="">
              <FontAwesomeIcon color="white" icon={faUser} />
            </LinkContainer>
            <div className="d-lg-none" style={{ position: "relative" }}>
              <LinkContainer to="/basket" style={{ width: "20px", height: "20px" }} activeClassName="" aria-label="Panier">
                <Button variant="outline-light" className="round p-2">
                  <FontAwesomeIcon fixedWidth={true} style={{ width: "20px",height: "20px", position: "absolute", left: "7px", top: "7px" }} color="white" icon={faShoppingCart} />
                  {countBasket > 0 ? (
                    <Badge style={{ position: "absolute", right: "-5px" }} pill variant="danger">
                      {countBasket}
                    </Badge>
                  ) : null}
                </Button>
              </LinkContainer>
            </div>
          </div>
          <div className="d-none d-lg-flex" style={{display: "flex",
            flexDirection: "row",
            flexWrap: "no-wrap",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: "1"
          }}>
            <CenterNavBar />
            <Nav style={{flexGrow: "1", justifyContent: "flex-end"}}>
              <div className="d-none d-lg-block" style={{ position: "relative", width: "60px" }}>
                <CreateLetterImageButton size={40}/>
              </div>
              <LinkContainer className="d-none d-md-block d-sm-block" to="/dashboard" activeClassName="">
                <Button variant="outline-light" className="withMarginRight accountNavBar">
                  Mon compte
                </Button>
              </LinkContainer>
              <div className="d-none d-lg-block" style={{ position: "relative", width: "60px" }}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props)=>MyTooltip({...props, title: "Mon panier"})}>
                  <LinkContainer to="/basket" activeClassName="">
                    <Button variant="outline-light" className="round p-2">
                      <FontAwesomeIcon style={{ width: "20px", height:"20px", position: "absolute", left: "10px", top: "10px" }} color="white" icon={faShoppingCart} />
                      {countBasket > 0 ? (
                        <Badge style={{ position: "absolute", right: "10px" }} pill variant="danger">
                          {countBasket}
                        </Badge>
                      ) : null}
                    </Button>
                  </LinkContainer>
                </OverlayTrigger>
              </div>
            </Nav>
          </div>
        </div>
        <div className="d-xs-inline d-lg-none mr-2">
          <Navbar.Collapse id="basic-navbar-nav">
            <CenterNavBar />
            <Nav>
              <div className="d-none d-lg-block" style={{ position: "relative", width: "60px" }}>
                <CreateLetterImageButton size={40}/>
              </div>
              <LinkContainer className="d-none d-md-block d-sm-block" to="/dashboard" activeClassName="">
                <Button variant="outline-light" className="withMarginRight accountNavBar">
                  Mon compte
                </Button>
              </LinkContainer>
              <div className="d-none d-lg-block" style={{ position: "relative", width: "60px" }}>
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 0, hide: 500 }}
                  overlay={(props)=>MyTooltip({...props, title: "Mon panier"})}>
                  <LinkContainer to="/basket" activeClassName="">
                    <Button variant="outline-light" className="round p-2">
                      <FontAwesomeIcon style={{ width: "20px", height:"20px", position: "absolute", left: "10px", top: "10px" }} color="white" icon={faShoppingCart} />
                      {countBasket > 0 ? (
                        <Badge style={{ position: "absolute", right: "10px" }} pill variant="danger">
                          {countBasket}
                        </Badge>
                      ) : null}
                    </Button>
                  </LinkContainer>
                </OverlayTrigger>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </>
    )
  } else {
    return null
  }
}

export const ConnectedNavBar = () => {
  return (
    <Navbar variant="dark" expand="lg" className="appnavbar navbar-dark px-2 px-sm-4">
      <ConnectNavBarContent />
    </Navbar>
  )
}
export const ConnectedNavBarTransparent = () => {
  return (
    <Navbar className="hp-nav maxWidthContainer px-1 navbar-dark" expand="lg">
      <ConnectNavBarContent />
    </Navbar>
  )
}

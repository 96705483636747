import React from "react"

export const PressPages = [{
    path: "/presse/01net",
    fileName: "../pr/01net.md",
    title: "01net Magazine mentionne CliquezPostez.com comme alternative économique à La Poste",
    description: "01net Magazine parle de nous"
},{
    path: "/presse/la-tribune",
    fileName: "../pr/la-tribune.md" ,
    title: "Mention de CliquezPostez dans La Tribune",
    description: "La Tribune parle de nous"
},{
    path: "/presse/petit-journal",
    fileName: "../pr/petit-journal.md",
    title: "Mention de CliquezPostez dans le Petit Journal",
    description: "Le Petit Journal parle de nous"
},{
    path: "/presse/petite-republique",
    fileName: "../pr/petite-republique.md",
    title: "Mention de CliquezPostez dans La Petite République",
    description: "La Petite République parle de nous"
}]
export default PressPages
import React, {useContext, useEffect, useMemo, useState} from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import Storage from "@aws-amplify/storage"
import Auth from "@aws-amplify/auth"
import {
  associateCognitoIdWithACcountId,
  createAccount as CreateAccount,
  updateAccount as UpdateAccount,
} from "../graphql/mutations/AccountMutations"
import {AuthContext} from "../components/AuthComponent"
import config from "../aws-exports"
import {v4 as uuid} from "uuid"
import {Alert} from "react-bootstrap"
import {AnalyticsContext} from "./AnalyticsComponent"
import {getAccountWithoutLetters} from "../customQueries"

const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config

//We create with a default empty context for autocompletion
export const AccountContext = React.createContext({
  account: null,
  user: null,
  credits: 0.0,
  updateAccount: () => {},
  uploadFilesAndSubmit: () => {},
  updateOrganization: () => {},
})

async function fetchAccount(username) {
  let accountsOperationResult = await API.graphql(graphqlOperation(getAccountWithoutLetters, { id: username }))
  return accountsOperationResult.data.getAccount
}

async function createAccount(username, onAccountCreated) {
  console.log(`creating account for ${username}`)
  const inputData = { id: username }
  const credentials = await Auth.currentCredentials()
  const cognitoID = credentials.identityId
  if(!cognitoID){
    throw new Error("cannot create account because cognitoID is null")
  }
  await API.graphql(graphqlOperation(CreateAccount, { input: inputData }))
  API.graphql(
    graphqlOperation(associateCognitoIdWithACcountId, {
      id: cognitoID,
    })
  )
  onAccountCreated()
}

export const AccountProvider =({children }) => {
  const {onAccountCreated} = useContext(AnalyticsContext)
  const {user, isLoadingUser} = useContext(AuthContext)
  // const { setLoading } = useFullScreenLoader("Chargement de votre compte")
  const username = useMemo(()=>user && user.username, [user])
  const [account, setAccount] = useState(null)
  const [accountInitializationError, setAccountInitializationError] = useState(null)
  const [isLoadingAccountInternal, updateIsLoadingAccountInternal] = useState(false)
  const isLoadingAccount = useMemo(()=>(isLoadingUser || isLoadingAccountInternal), [isLoadingUser, isLoadingAccountInternal])

  useEffect(() => {
    let cancelled = false
    // setLoading(true)
    const setAccountIfNotCancelled = async () => {
      const getOrCreateAndThenGet = async () => {
        updateIsLoadingAccountInternal(true)
        try{
          let accnt = await fetchAccount(username)
          if (accnt == null) {
            await createAccount(username, onAccountCreated)
            return fetchAccount(username)
          } else {
            return accnt
          }
        }finally {
          updateIsLoadingAccountInternal(false)
        }
      }
      const accnt = await getOrCreateAndThenGet()
      if (!cancelled) {
        // setLoading(false)
        setAccount(accnt)
      }
    }

    if (username) {
      console.log(`initialize account for ${username}`)
      setAccountIfNotCancelled().catch((e) => {
        if (!cancelled) {
          // setLoading(false)
          setAccountInitializationError("Oups, une erreur inattendue s'est produite durant l'initialisation de votre compte. Veuillez recharger la page et réessayer.\n")
        }
        console.error("error during account retrieval", e)
      })
    } else {
      console.log("no user connected account cleared")
      setAccount(null)
    }

    return () => {
      console.log("cancelling account")
      // setLoading(false)
      cancelled = true
    }
  }, [username, fetchAccount, setAccount, updateIsLoadingAccountInternal])


  const uploadFileAsAttachment = async (file, folder = "documents-sender")=> {
    const extension = file.name.split(".")[1];
    const {type: mimeType} = file;
    const uid = uuid();
    const key = `${account.id}/${folder}/${uid}.${extension}`;
    const storeInput = {file, key, mimeType}
    await Storage.put(key, file, {
      level: 'private',
      contentType: mimeType
    });
    const pages = storeInput.numPages;
    return {
      format: mimeType,
      fileName: file.name,
      contentLength: file.size,
      pages: pages,
      content: {
        bucket,
        key,
        region,
      }
    }
  }

  const uploadFilesToAccount = async ({ idFile1, idFile2, fileKbis }) => {
    const update = {}
    let idDocument = account.idDocument;
    if (!idDocument && idFile1) {
      idDocument = { status: "to_verify" }
    }
    let kbis = account.kbis
    if (!kbis && fileKbis) {
      kbis = { status: "to_verify" }
    }
    if (idFile1) {
      if(!idDocument.attachments){
        idDocument.attachments = []
      }
      const fileLink = await uploadFileAsAttachment(idFile1)
      idDocument.attachments.push(fileLink)
      update.idDocument = idDocument
    }
    if (idFile2) {
      if(!idDocument.attachments){
        idDocument.attachments = []
      }
      const fileLink = await uploadFileAsAttachment(idFile2)
      idDocument.attachments.push(fileLink)
      update.idDocument = idDocument
    }
    if (fileKbis) {
      if(!kbis.attachments){
        kbis.attachments = []
      }
      const fileLink = await uploadFileAsAttachment(fileKbis)
      kbis.attachments.push(fileLink)
      update.kbis = kbis
    }
    await updateAccount(update)
    await refreshAccount()
  }

  const updateSenderFromLetterEdition = async ({ sender }) => {
    const update = {}
    if (sender) {
      sender.recipientInContacts = undefined
      sender.hasGivenLREConsent = undefined
      update.sender = sender
    }
    await updateAccount(update)
    await refreshAccount()
  }

  const updateAccount = async (updateContent) => {
    console.log(`updateSender with account version: ${account.version}`)
    const mutation = {
      id: account.id,
      expectedVersion: account.version,
      ...updateContent,
    }
    await API.graphql(
      graphqlOperation(UpdateAccount, {
        input: mutation,
      })
    )
    await refreshAccount()
  }

  const updateOrganization = async (organization) => {
    console.log("updateOrganization")
    const mutation = {
      id: account.id,
      expectedVersion: account.version,
      organization: organization,
    }
    await API.graphql(graphqlOperation(UpdateAccount, { input: mutation }))
    await refreshAccount()
  }

  const clearDocument = async (name) => {
    console.log("clearDocument")
    const mutation = {
      id: account.id,
      expectedVersion: account.version,
    }
    mutation[name] = null
    await API.graphql(graphqlOperation(UpdateAccount, { input: mutation }))
    await refreshAccount()
  }

  const refreshAccount = async () => {
    console.log("refreshing")
    const accnt = await fetchAccount(username)
    setAccount(accnt)
  }

  const saveAddressHolderBackground = async ({ addressHolderBackground }) => {
    const update = {}
    let addressHolder = account.defaultAddressHolderBackground
    if(!addressHolder){
      addressHolder = {}
    }
    if(!addressHolder.attachments){
      addressHolder.attachments = []
    }
    const fileLink = await uploadFileAsAttachment(addressHolderBackground, "addressHolder")
    addressHolder.attachments.push(fileLink)
    update.defaultAddressHolderBackground = addressHolder
    await updateAccount(update)
  }

  const clearAddressHolderBackground = async (attachment) => {
    console.log("clearDocument")
    const mutation = {
      id: account.id,
      expectedVersion: account.version,
      defaultAddressHolderBackground: null
    }
    await API.graphql(graphqlOperation(UpdateAccount, { input: mutation }))
    await refreshAccount()
  }

  const saveDefaultSignature = async ({defaultSignature})=>{
    const update = {}
    let defSign = account.defaultSignature
    if(!defSign){
      defSign = {}
    }
    if(!defSign.attachments){
      defSign.attachments = []
    }
    const fileLink = await uploadFileAsAttachment(defaultSignature, "defaultSignature")
    defaultSignature.attachments.push(fileLink)
    update.defaultSignature = defaultSignature
    await updateAccount(update)
  }
  const activateMultipleSenderAddresses = async  ()=>{
    const update = { accountOptions: { isMultipleSenderAddressesActivated: true }}
    await updateAccount(update)
  }

  const credits = account && account.credits ? account.credits : 0
  const freeLetters = account && account.freeLetters ? account.freeLetters : 0
  return (
    <AccountContext.Provider
      value={{
        isLoadingAccount,
        account,
        user,
        email: user && user.attributes.email,
        credits: credits,
        freeLetters,
        updateSender: updateAccount,
        refreshAccount,
        updateOrganization,
        uploadFilesToAccount,
        updateSenderFromLetterEdition,
        clearDocument,
        saveAddressHolderBackground,
        clearAddressHolderBackground,
        saveDefaultSignature,
        activateMultipleSenderAddresses
      }}
    >
      { accountInitializationError ? <Alert variant="danger">{accountInitializationError}</Alert> :  <>{children}</> }
    </AccountContext.Provider>
  )
}

export const AccountConsumer = AccountContext.Consumer

export const withAccount = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return (
        <AccountConsumer>
          {(ownProps) => <Component {...ownProps} {...passThroughProps} />}
        </AccountConsumer>
      )
    }
  }
  return ComponentWrapperWithAccountPropForChild
}

export const createLead = /* GraphQL */ `
  mutation CreateLead($input: CreateLeadInput!) {
    createLead(input: $input) {
      id
      name
      phoneNumber
      email
      comment
      createdAt
      updatedAt
    }
  }
`;
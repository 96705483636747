import React from "react"

export const AnalyticsContext = React.createContext();

export const AnalyticsProvider =({children}) => {

  const push = (eventBody)=>{
    console.log("pushing event")
    console.log(eventBody)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventBody)
  }

  const onAccountCreated = ()=>{
    push({
      event: 'accountCreated'
    })
  }
  const mailModeName = (mailModeKey) => {
    if(mailModeKey === "ECO"){
      return "Lettre papier"
    }else if(mailModeKey === "LR"){
      return "Lettre recommandée"
    }else if(mailModeKey === "LR-AR"){
      return "Lettre recommandée avec AR"
    }else if(mailModeKey === "LRE"){
      return "Lettre recommandée électronique"
    }else if(mailModeKey === "LRE-AR"){
      return "Lettre recommandée électronique avec AR"
    }else{
      return "Mode d'envoi non supporté"
    }
  }
  const onLetterAddedToBasket = (mailMode, price, recipientName)=>{
    push({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [{
            id: mailMode,                                      // Votre identifiant interne pour le produit. Si vous n'utilisez pas de système d'id en interne, possibilité d'utiliser les valeurs suivantes :
            // LRE : Lettre Recommandée Electronique
            // LR  : Lettre Recommandée Classique
            // LP  : Lettre papier
            name: mailModeName(mailMode),        // Le nom du produit.
            price: price,                                    // Le prix du produit en TTC ou HT en fonction des préferences. Cette valeur remontera dans les rapports Analytics.
            brand: 'Cliquez Postez',                        // La marque du produit. Cela ne s'applique pas ici, possibilité de laisser "Cliquez Postez" en statique.
            quantity: 1,                                    // La quantité de ce produit ajoutée au panier. Probablement valeur statique de 1.
            dimension1: recipientName,                           // Le destinataire de la lettre
          }]
        }
      }
    })
  }
  const onOrderConfirmed = (orderId, priceWithoutTax, taxAmount, coupon, letters, letterPrices)=> {
    push({event: "baskedOrderConfirmed"})
    push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: orderId,         // L'identifiant de la transaction.
            revenue: priceWithoutTax,           // Montant HT total de la transaction.
            tax: taxAmount,                 // Montant de la TVA.
            shipping: 0,              // Montant des frais de livraison. N/A ici donc 0.
            coupon: coupon      // Le nom du coupon utilisé si existant, un string vide sinon.
          },
          products: letters.map(l =>{
            const lPriceObject = letterPrices[l.id]
            const letterPrice = lPriceObject.isFreeLetter ? 0 : lPriceObject.price.toFixed(2)
            return ({
            id: l.mailMode,
            name: mailModeName(l.mailMode),
            price: letterPrice,
            brand: 'Cliquez Postez',
            quantity: 1
          })})
        }
      }
    })
  }

  const onCallbackFormSubmitted = ()=>{
    push({
      event: 'formSent'
    })
  }
  const onTemplateOpened = (templateId, recipientId) => {
    push({event: "templateOpened",
      templateId,
      recipientId
    })
  }
  const onTemplateInteractionStarted = (templateId, recipientId) => {
    push({event: "templateInteractionStarted", templateId, recipientId})
  }
  const onLetterGeneratedFromTemplate = (templateId, recipientId) => {
    push({event: "letterGeneratedFromTemplate", templateId, recipientId})
  }
  const onLetterDownloadedFromTemplate = (templateId, recipientId) => {
    push({event: "letterDownloadedFromTemplate", templateId, recipientId})
  }
  const onCreateLetterStepSubmitted = (submittedStepName, templateId, recipientId) =>{
    push({event: `createLetter${submittedStepName}Submitted`, templateId, recipientId})
  }
  const onCreateAccountClicked = (page)=>{
    push({event: "createAccountClicked"})
  }

  const onSecondStepOfBasketSelected = (basketId)=>{
    push({event: "selectSecondStepOfBasket", basketId})
  }

  const onRateExperienceClicked = (like, templateId, recipientId)=>{
    if(like){
      push({event: "letterDownloadLike", templateId, recipientId})
    }else{
      push({event: "letterDownloadDislike", templateId, recipientId})
    }
  }
  const onDismissRecoveryClicked = (templateId, recipientId)=>{
    push({event: "letterDownloadRecoveryDismissed", templateId, recipientId})
  }
  const onFinalizeRecovery = (useOurServices, templateId, recipientId) =>{
    if(useOurServices){
      push({event: "letterDownloadFinalizeSendWithUs", templateId, recipientId})
    }else{
      push({event: "letterDownloadFinalizeNoThanks", templateId, recipientId})
    }
  }

  const onExpressPopinShown = (templateId, recipientId)=>{
    push({event: "expressPopinShown", templateId, recipientId})
  }

  const onExpressPopinDismissed = (templateId, recipientId) => {
    push({event: "expressPopinDismissed", templateId, recipientId})
  }

  const onExpressPaymentSubmitted = (letter, templateId, recipientId, priceWithTax, taxAmount) => {
    push({event: "expressPaymentSubmitted", templateId, recipientId})
    push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: `express:${letter.id}`,         // L'identifiant de la transaction.
            revenue: priceWithTax - taxAmount,           // Montant HT total de la transaction.
            tax: taxAmount,                 // Montant de la TVA.
            shipping: 0,              // Montant des frais de livraison. N/A ici donc 0.
            coupon: ""      // Le nom du coupon utilisé si existant, un string vide sinon.
          },
          products: [{
              id: letter.mailMode,
              name: mailModeName(letter.mailMode),
              price: priceWithTax-taxAmount,
              brand: 'Cliquez Postez',
              quantity: 1
            }]
        }
      }
    })

  }

  return (
    <AnalyticsContext.Provider
      value={{
       onAccountCreated,
        onLetterAddedToBasket,
        onOrderConfirmed,
        onCallbackFormSubmitted,
        onTemplateOpened,
        onTemplateInteractionStarted,
        onLetterGeneratedFromTemplate,
        onCreateLetterStepSubmitted,
        onCreateAccountClicked,
        onLetterDownloadedFromTemplate,
        onSecondStepOfBasketSelected,
        onRateExperienceClicked,
        onDismissRecoveryClicked,
        onFinalizeRecovery,
        onExpressPopinShown,
        onExpressPopinDismissed,
        onExpressPaymentSubmitted
      }}
    >{children}
    </AnalyticsContext.Provider>
  )
}
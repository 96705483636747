import React, {Suspense} from "react"
import Route from "react-router-dom/Route"
import {BottomNavBar} from "../BottomNavBar"
import {CallbackAndFaqBanner} from "../CallbackBanner";

const AppRoute = ({ component: Component, layout: Layout, componentProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (
        <>
          {Layout ? (
            <Layout>
                <Suspense fallback={<></>}>
                    <Component {...componentProps} />
                </Suspense>
            </Layout>
          ) : (
              <Suspense fallback={<></>}>
                <Component {...componentProps} />
              </Suspense>
          )}
            <CallbackAndFaqBanner />
          <BottomNavBar />
        </>
      )}
    />
  )
}

export default AppRoute

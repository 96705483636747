import React from "react"
import ProgressiveImage from "react-progressive-graceful-image"
import { Container, Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import hpBgFooter from "../../assets/images/compressed/hp-bg-footer.png"
import hpBgFooterThumb from "../../assets/images/compressed/hp-bg-footer-thumb.svg"
import s from "./styles.module.scss"
import { useLocation } from "react-use"

const BottomFaqBanner = () => {
  const { pathname } = useLocation()

  if (pathname === "/faq") return null

  return (
    <div className="position-relative text-white">
      <ProgressiveImage src={hpBgFooter} placeholder={hpBgFooterThumb}>
        {(src) => (
          <img
            src={src}
            alt="bannière footer"
            style={{ objectFit: "cover", zIndex: "-1", position: "absolute", height: "100%", width: "100%" }}
          />
        )}
      </ProgressiveImage>

      <Container style={{ textAlign: "center", paddingTop: "100px", paddingBottom: "100px" }}>
        <h3 className={s.title}>Une question ?</h3>
        <p className="chapo" style={{ marginBottom: "60px" }}>
          Retrouvez dans notre foire aux questions l'essentiel des réponses les plus couramment recherchées. N'hésitez
          surtout pas à nous contacter si vous avez encore des interrogations après sa lecture !
        </p>
        <Link to="/faq">
          <Button variant="outline-light">Consulter notre FAQ</Button>
        </Link>
      </Container>
    </div>
  )
}

export default BottomFaqBanner

/**
 * This file will include all api operations related to payments.
 * The goal is to remove the api details from the component so it can focus on business logic.
 */

import { getPaypalPayments } from "../../graphql/queries"
import API, { graphqlOperation } from "@aws-amplify/api"
import { createPaypalPayment } from "../../graphql/mutations/BasketMutations"

export const fetchLastPayments = async (accountId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(getPaypalPayments, {
        owner: accountId,
        limit: 999,
      })
    )
    return result.data.getPaypalPayments
  } catch (e) {
    console.error(e)
    console.error("has failed fetchLastPayments")
    if (e.data.getPaypalPayments) {
      return e.data.getPaypalPayments
    }
    throw e
  }
}

export const createPaymentInDb = async (orderId, basketId) => {
  console.log("creating payment in db:")
  const mutation = { orderId, basketId }
  await API.graphql(graphqlOperation(createPaypalPayment, mutation))
}

import {useHistory} from "react-router-dom"
import React, {createContext} from "react"
import API, {graphqlOperation} from "@aws-amplify/api"
import {searchTemplateNavigation, findTemplateNavigation} from "../graphql/queries"
import {act} from "react-dom/test-utils";

export const apiSearchTemplates = async (query, scope) => {
  console.log(`querying template navigation with: ${query}`)
  const result = await API.graphql({
    ...graphqlOperation(searchTemplateNavigation, {
      //filter: {status: {neq: "deleted"}},
      //nextToken: nextToken,
      //limit: pageSize,
      query,
      scope
    }), authMode: "API_KEY" }
  )
  console.log(result);
  return JSON.parse(result.data.searchTemplateNavigation)
}
export const apiParseLocation = async (path) => {
  console.log(`parsing location for ${path}`)
  const result = await API.graphql({
    ...graphqlOperation(findTemplateNavigation, {
      //filter: {status: {neq: "deleted"}},
      //nextToken: nextToken,
      //limit: pageSize,
      path
    }), authMode: "API_KEY" }
  )
  console.log(result);
  const json = JSON.parse(result.data.findTemplateNavigation);
  console.log(json)
  return json
}


export const TemplateNavigationContext = createContext();

export const TemplateNavigationProvider = ({children}) => {
  const history = useHistory()

  const openNavItem = (location, childNavItem) => {
    let path = "/modele-lettre-recommandee"
    const actualPath = location.pathname
    if(actualPath.startsWith("/modele-lettre-recommandee")){
      path = actualPath
    }
    if(actualPath.startsWith("/modeles")){
      path = actualPath.replace("/modeles","/modele-lettre-recommandee")
    }
    // console.log(`location: ${path}`)
    // const slugs = path.split("/")
    //     .filter(segment => segment !== "modeles")
    //     .filter(segment => segment !== "modele-lettre-recommandee")
    //   .filter(segment => segment !== "")
    const newLocation = `/modele-lettre-recommandee/${childNavItem.slugs[0]}`
    // console.log(`newLocation: ${newLocation}`)
    return newLocation
  }

  const parseLocation = async (location) =>{
    const path = location.pathname
    return await apiParseLocation(path)
  }


  return <TemplateNavigationContext.Provider
    value={{
      parseLocation,
      openNavItem,
      searchTemplates: apiSearchTemplates
    }}
  >
    {children}
  </TemplateNavigationContext.Provider>
}
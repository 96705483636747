//This class is almost like BasketComponent except it deals with generating receipt and receipt pages
import React, { useContext, useEffect, useMemo, useReducer, useState } from "react"
import { AccountContext } from "./AccountComponent"
import API, { graphqlOperation } from "@aws-amplify/api"
import { getInvoiceForOrder, listBasketsSorted } from "../graphql/queries"
import { getInvoiceForBasket } from "../graphql/queries"
import { PaginationContext, withPagination } from "./PaginationComponent"
import { apiSubscribeBasketUpdate, apiSubscribeCreateBasket } from "./BasketApi"

export const PurchaseContext = React.createContext(null)

const fetchNextPagePurchases = async (nextToken, pageSize = 5) => {
  const result = await API.graphql(
    graphqlOperation(listBasketsSorted, {
      //filter: { status: { eq: "draft" } },
      status: { ne: "draft" },
      nextToken: nextToken,
      limit: pageSize,

      //owner: accountId,
    })
  )
  const baskets = result.data.listBasketsSorted
  return baskets
}

export const PurchaseProvider = withPagination(fetchNextPagePurchases, ({ children }) => {
  const { account } = useContext(AccountContext)
  const {
    invalidateFirstPage,
    currentPageItems,
    pageIndex,
    hasNextPage,
    browseNextPage,
    browsePreviousPage,
  } = useContext(PaginationContext)

  const [lastPurchases, setLastPurchases] = useState([])
  const [isLoadingLastPurchases, setLoadingLastPurchases] = useState(false)

  const accountId = useMemo(() => (account ? account.id : null), [account])

  const refreshLastPurchases = async () => {
    console.log("refreshing last letters")
    if (accountId) {
      const firstPage = await fetchNextPagePurchases(null, 10)
      setLastPurchases(firstPage.items)
      invalidateFirstPage()
    }
  }

  useEffect(() => {
    if(accountId){
      let cancelled = false
      setLoadingLastPurchases(true)
      refreshLastPurchases()
        .then(() => setLoadingLastPurchases(false))
        .catch((e) => {
          if (!cancelled) {
            setLoadingLastPurchases(false)
          }
          console.error("error loading letters")
          console.error(e)
        })
      return () => {
        cancelled = true
      }
    }
  }, [accountId])

  useEffect(() => {
    if (accountId) {
      console.log(`subscribing to purchase (basket) update and creation for : ${accountId}`)
      const onNext = async () => {
        await refreshLastPurchases()
      }
      const subscriptionUpdateBasket = apiSubscribeBasketUpdate(accountId, onNext)
      const subscriptionCreateBasket = apiSubscribeCreateBasket(accountId, onNext)
      return () => {
        subscriptionUpdateBasket.unsubscribe()
        subscriptionCreateBasket.unsubscribe()
      }
    }
  }, [accountId])

  const downloadInvoice = async (basketId) => {
    const result = await API.graphql(
      graphqlOperation(getInvoiceForBasket, {
        basket: basketId,
      })
    )
    console.log(result)
    const b64PDFBlurb = JSON.parse(result.data.getInvoiceForBasket)

    if (b64PDFBlurb.status == "FAIL") {
      return { status: "FAIL", file: b64PDFBlurb.file }
    }

    return { status: "SUCCESS", file: `data:application/pdf;base64,${b64PDFBlurb.file}`, number: b64PDFBlurb.number}
  }

  const downloadInvoiceCredit = async (orderId) => {
    const result = await API.graphql(
      graphqlOperation(getInvoiceForOrder, {
        orderId: orderId,
      })
    )
    const b64PDFBlurb = JSON.parse(result.data.getInvoiceForOrder)
    return {file: `data:application/pdf;base64,${b64PDFBlurb.file}`, number: b64PDFBlurb.number}
  }

  return (
    <PurchaseContext.Provider
      value={{
        browseNextPage: browseNextPage,
        browsePreviousPage: browsePreviousPage,
        hasNextPage: hasNextPage,
        pageIndex: pageIndex,
        currentPagePurchases: currentPageItems,
        lastPurchases: lastPurchases,
        isLoadingLastPurchases,
        getInvoiceForOrder: downloadInvoiceCredit,
        getInvoiceForBasket: downloadInvoice,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  )
})

export const PurchaseConsumer = PurchaseContext.Consumer

export const withPurchases = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <PurchaseConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</PurchaseConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}

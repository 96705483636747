import React, {useContext, useState} from "react"
import {Alert, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {
//     faBalanceScale, faInbox, faLock,
//     faMailBulk, faPaperPlane,
//     faPen,
//     faPiggyBank,
//     faRunning,
//     faStopwatch, faToiletPaper, faTruckMoving
// } from "@fortawesome/free-solid-svg-icons";

export const PriceComparator = ({children})=>{
    return <Alert variant="info" style={{marginTop: "15px"}}>
        <h3>Envoyez maintenant votre lettre en <strong>Recommandé avec AR</strong> pour <strong>4,68€</strong></h3>
        {children}
    </Alert>
}
export const TemplateBottomBanner  = ()=>{
    // return <div style={{
    //     backgroundColor: "#F5F6F8",
    //     padding: "50px",
    //     marginTop: "20px",
    //     justifyContent: "center"}}>
    //     <Container>
    return <></>

        // </Container>


    // </div>
}

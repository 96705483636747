import React, {createContext, useEffect, useMemo, useState} from "react"
import { withAccount } from "./AccountComponent"
import { createPaymentInDb, fetchLastPayments } from "./api/payments"
import { createBasket } from "../graphql/mutations/BasketMutations"
import { apiCreateNewBasket, apiListLastBaskets, apiCreditsNewBasket, apiCreditsGetBasket } from "./BasketApi"
import API, { graphqlOperation }from "@aws-amplify/api"
import {
  updateAccount as UpdateAccount,
} from "../graphql/mutations/AccountMutations"

export const PaymentsContext = createContext(null)

const useLastPaymentsDataSource = (accountId) => {
  const [isLoading, setLoading] = useState(true)
  const [lastPayments, setLastPayments] = useState(null)

  useEffect(() => {
    console.log("Initializing last payments effect")
    let cancelled = false //We use this to stop modifying an unmounted component
    const getLastPayments = async (accountId) => {
      const payments = await fetchLastPayments(accountId)
      if (!cancelled) {
        setLoading(false)
        setLastPayments(payments.filter((p) => p.basket == null))
      }
    }
    if (accountId) {
      setLoading(true)
      getLastPayments(accountId).catch((e) => {
        console.log("Error while initializing")
        console.log(e)
      })
    } else {
      setLastPayments(null)
      setLoading(true)
    }
    return () => {
      cancelled = true
    }
  }, [accountId, fetchLastPayments, setLastPayments, setLoading])

  const refreshLastPayments = async () => {
    const payments = await fetchLastPayments(accountId)
    setLastPayments(payments.filter((p) => p.basket == null))
  }
  return {
    refreshLastPayments,
    isLoadingLastPayments: isLoading,
    lastPayments,
  }
}

export const PaymentsProvider = withAccount(({ children, account }) => {
  const accountId = useMemo(()=>account && account.id,[account])

  const { refreshLastPayments, isLoadingLastPayments, lastPayments } = useLastPaymentsDataSource(accountId)

  console.log("lastPayments")
  console.log(lastPayments)

  const createPayment = async (payment, basketId = "credits") => {
    const orderId = payment.orderID
    if (basketId === "credits")
    {
      // check if account already has a basketCredits field
      console.log("BOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOYAAAAAAAAAAAAAAAAAAAA")
      console.log(account)

      let currentCreditsBasket = await apiCreditsGetBasket(accountId)
      let currentCreditsBasketId = null;

      console.log("Current Credits Basket")
      console.log(currentCreditsBasket)

      // if currentCreditsBasket is undefined
      if (currentCreditsBasket === undefined)
      {
        console.log("Creating new basket");
        currentCreditsBasket = await apiCreditsNewBasket(accountId);
        //const baskets = await apiListLastBaskets(accountId)
        currentCreditsBasketId = currentCreditsBasket.data.createBasket.id;
      } else {
        currentCreditsBasketId = currentCreditsBasket.id;
      }
      
      console.log("New Basket Credit");
      console.log(currentCreditsBasket)
      console.log(currentCreditsBasketId)

      await createPaymentInDb(orderId, currentCreditsBasketId)
    }
    else
    {
      await createPaymentInDb(orderId, basketId);
    }
    //await refreshLastPayments()
  }

  return (
    <PaymentsContext.Provider
      value={{
        refreshLastPayments,
        isLoadingLastPayments,
        lastPayments,
        createPayment,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  )
})

export const PaymentsConsumer = PaymentsContext.Consumer

export const withPayments = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <PaymentsConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</PaymentsConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLetterPrice = /* GraphQL */ `
  query GetLetterPrice($mode: String!, $pages: Float!) {
    getLetterPrice(mode: $mode, pages: $pages)
  }
`;
export const getPaypalPayments = /* GraphQL */ `
  query GetPaypalPayments($limit: Int, $start: Int) {
    getPaypalPayments(limit: $limit, start: $start) {
      id
      createdAt
      updatedAt
      date
      amount
      currency
      orderId
      type
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      basket {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      __typename
    }
  }
`;
export const getInvoiceForBasket = /* GraphQL */ `
  query GetInvoiceForBasket($basket: String!) {
    getInvoiceForBasket(basket: $basket)
  }
`;
export const getInvoiceForOrder = /* GraphQL */ `
  query GetInvoiceForOrder($orderId: String!) {
    getInvoiceForOrder(orderId: $orderId)
  }
`;
export const listLettersSorted = /* GraphQL */ `
  query ListLettersSorted($limit: Int, $nextToken: String) {
    listLettersSorted(limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        recipient {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          recipientInContacts
          __typename
        }
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        mailMode
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          recipientInContacts
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        letterAccountId
        status
        transports {
          id
          reference
          __typename
        }
        trackingNumber
        tracking {
          items {
            id
            createdAt
            updatedAt
            letter {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            status
            submittedDate
            deliveredDate
            registeredStatus
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        price
        priceht
        taxes
        receipt {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        sentAt
        template {
          templateId
          templateName
          requiredDocuments {
            name
            description
            __typename
          }
          hasValidatedRequiredDocuments
          recipientId
          __typename
        }
        addressHolder {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        addressHolderBackground {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        clearAddressHolderBackground
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listBasketsSorted = /* GraphQL */ `
  query ListBasketsSorted($status: String, $limit: Int, $nextToken: String) {
    listBasketsSorted(status: $status, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormTokenForBasket = /* GraphQL */ `
  query GetFormTokenForBasket($basketId: String, $amount: Float!) {
    getFormTokenForBasket(basketId: $basketId, amount: $amount)
  }
`;
export const findTemplateNavigation = /* GraphQL */ `
  query FindTemplateNavigation($path: String) {
    findTemplateNavigation(path: $path)
  }
`;
export const searchTemplateNavigation = /* GraphQL */ `
  query SearchTemplateNavigation($query: String, $scope: String) {
    searchTemplateNavigation(query: $query, scope: $scope)
  }
`;
export const detectRecipient = /* GraphQL */ `
  query DetectRecipient($bucket: String, $key: String) {
    detectRecipient(bucket: $bucket, key: $key)
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      createdAt
      updatedAt
      id
      username
      sender {
        formOfAddress
        firstName
        lastName
        isProfessional
        companyName
        addressPrefix
        address
        zipCode
        city
        email
        phoneNumber
        instructions
        phoneNumberLandline
        __typename
      }
      idDocument {
        parts {
          bucket
          region
          key
          __typename
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        __typename
      }
      kbis {
        parts {
          bucket
          region
          key
          __typename
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        __typename
      }
      letters {
        items {
          createdAt
          updatedAt
          id
          recipient {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
            __typename
          }
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          mailMode
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          letterAccountId
          status
          transports {
            id
            reference
            __typename
          }
          trackingNumber
          tracking {
            items {
              id
              createdAt
              updatedAt
              status
              submittedDate
              deliveredDate
              registeredStatus
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          price
          priceht
          taxes
          receipt {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          sentAt
          template {
            templateId
            templateName
            requiredDocuments {
              name
              description
              __typename
            }
            hasValidatedRequiredDocuments
            recipientId
            __typename
          }
          addressHolder {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          addressHolderBackground {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          clearAddressHolderBackground
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          createdAt
          updatedAt
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      senderAddresses {
        items {
          id
          createdAt
          updatedAt
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      credits
      freeLetters
      payments {
        items {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      baskets {
        items {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      basketCredits {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      prepaidCodes {
        items {
          id
          useddate
          consumeddate
          createdAt
          updatedAt
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          mailmodes
          owner
          __typename
        }
        nextToken
        __typename
      }
      creditOrders {
        items {
          createdAt
          updatedAt
          id
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          showAsPurchaseOrder
          remainingCredits
          amountCreditOrderBaskets {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      defaultAddressHolderBackground {
        parts {
          bucket
          region
          key
          __typename
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        __typename
      }
      defaultSignature {
        parts {
          bucket
          region
          key
          __typename
        }
        status
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        __typename
      }
      accountOptions {
        isMultipleSenderAddressesActivated
        __typename
      }
      version
      owner
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSenderAddress = /* GraphQL */ `
  query GetSenderAddress($id: ID!) {
    getSenderAddress(id: $id) {
      id
      createdAt
      updatedAt
      formOfAddress
      firstName
      lastName
      isProfessional
      companyName
      addressPrefix
      address
      zipCode
      city
      email
      phoneNumber
      instructions
      phoneNumberLandline
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listSenderAddresss = /* GraphQL */ `
  query ListSenderAddresss(
    $filter: ModelSenderAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSenderAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        formOfAddress
        firstName
        lastName
        isProfessional
        companyName
        addressPrefix
        address
        zipCode
        city
        email
        phoneNumber
        instructions
        phoneNumberLandline
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddressee = /* GraphQL */ `
  query GetAddressee($id: ID!) {
    getAddressee(id: $id) {
      id
      createdAt
      updatedAt
      formOfAddress
      firstName
      lastName
      isProfessional
      companyName
      addressPrefix
      address
      zipCode
      city
      email
      phoneNumber
      instructions
      phoneNumberLandline
      hasGivenLREConsent
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listAddressees = /* GraphQL */ `
  query ListAddressees(
    $filter: ModelAddresseeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddressees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        formOfAddress
        firstName
        lastName
        isProfessional
        companyName
        addressPrefix
        address
        zipCode
        city
        email
        phoneNumber
        instructions
        phoneNumberLandline
        hasGivenLREConsent
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAmountCreditOrderBasket = /* GraphQL */ `
  query GetAmountCreditOrderBasket($id: ID!) {
    getAmountCreditOrderBasket(id: $id) {
      id
      createdAt
      updatedAt
      amount
      basket {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      creditOrder {
        createdAt
        updatedAt
        id
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        showAsPurchaseOrder
        remainingCredits
        amountCreditOrderBaskets {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        version
        owner
        __typename
      }
      version
      owner
      __typename
    }
  }
`;
export const listAmountCreditOrderBaskets = /* GraphQL */ `
  query ListAmountCreditOrderBaskets(
    $filter: ModelAmountCreditOrderBasketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmountCreditOrderBaskets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        amount
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        creditOrder {
          createdAt
          updatedAt
          id
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          showAsPurchaseOrder
          remainingCredits
          amountCreditOrderBaskets {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          version
          owner
          __typename
        }
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBasket = /* GraphQL */ `
  query GetBasket($id: ID!) {
    getBasket(id: $id) {
      createdAt
      updatedAt
      id
      status
      letters {
        items {
          createdAt
          updatedAt
          id
          recipient {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
            __typename
          }
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          mailMode
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          letterAccountId
          status
          transports {
            id
            reference
            __typename
          }
          trackingNumber
          tracking {
            items {
              id
              createdAt
              updatedAt
              status
              submittedDate
              deliveredDate
              registeredStatus
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          price
          priceht
          taxes
          receipt {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          sentAt
          template {
            templateId
            templateName
            requiredDocuments {
              name
              description
              __typename
            }
            hasValidatedRequiredDocuments
            recipientId
            __typename
          }
          addressHolder {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          addressHolderBackground {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          clearAddressHolderBackground
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      basketCreditOrderAmounts {
        items {
          id
          createdAt
          updatedAt
          amount
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          creditOrder {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      freeLettersSpent
      totalSpent
      priceOfStamps
      lastUpdate
      payment {
        id
        createdAt
        updatedAt
        date
        amount
        currency
        orderId
        type
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        __typename
      }
      paymentDate
      version
      owner
      __typename
    }
  }
`;
export const listBaskets = /* GraphQL */ `
  query ListBaskets(
    $filter: ModelBasketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBaskets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCreditOrder = /* GraphQL */ `
  query GetCreditOrder($id: ID!) {
    getCreditOrder(id: $id) {
      createdAt
      updatedAt
      id
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      payment {
        id
        createdAt
        updatedAt
        date
        amount
        currency
        orderId
        type
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        __typename
      }
      showAsPurchaseOrder
      remainingCredits
      amountCreditOrderBaskets {
        items {
          id
          createdAt
          updatedAt
          amount
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          creditOrder {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      version
      owner
      __typename
    }
  }
`;
export const listCreditOrders = /* GraphQL */ `
  query ListCreditOrders(
    $filter: ModelCreditOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        showAsPurchaseOrder
        remainingCredits
        amountCreditOrderBaskets {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLetter = /* GraphQL */ `
  query GetLetter($id: ID!) {
    getLetter(id: $id) {
      createdAt
      updatedAt
      id
      recipient {
        formOfAddress
        firstName
        lastName
        isProfessional
        companyName
        addressPrefix
        address
        zipCode
        city
        email
        phoneNumber
        instructions
        phoneNumberLandline
        hasGivenLREConsent
        recipientInContacts
        __typename
      }
      attachments {
        contentLength
        fileName
        format
        pages
        content {
          bucket
          region
          key
          __typename
        }
        nature
        __typename
      }
      mailMode
      sender {
        formOfAddress
        firstName
        lastName
        isProfessional
        companyName
        addressPrefix
        address
        zipCode
        city
        email
        phoneNumber
        instructions
        phoneNumberLandline
        hasGivenLREConsent
        recipientInContacts
        __typename
      }
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      letterAccountId
      status
      transports {
        id
        reference
        __typename
      }
      trackingNumber
      tracking {
        items {
          id
          createdAt
          updatedAt
          letter {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          status
          submittedDate
          deliveredDate
          registeredStatus
          version
          owner
          __typename
        }
        nextToken
        __typename
      }
      basket {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      price
      priceht
      taxes
      receipt {
        contentLength
        fileName
        format
        pages
        content {
          bucket
          region
          key
          __typename
        }
        nature
        __typename
      }
      sentAt
      template {
        templateId
        templateName
        requiredDocuments {
          name
          description
          __typename
        }
        hasValidatedRequiredDocuments
        recipientId
        __typename
      }
      addressHolder {
        contentLength
        fileName
        format
        pages
        content {
          bucket
          region
          key
          __typename
        }
        nature
        __typename
      }
      addressHolderBackground {
        contentLength
        fileName
        format
        pages
        content {
          bucket
          region
          key
          __typename
        }
        nature
        __typename
      }
      clearAddressHolderBackground
      version
      owner
      __typename
    }
  }
`;
export const listLetters = /* GraphQL */ `
  query ListLetters(
    $filter: ModelLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLetters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        recipient {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          recipientInContacts
          __typename
        }
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        mailMode
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          recipientInContacts
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        letterAccountId
        status
        transports {
          id
          reference
          __typename
        }
        trackingNumber
        tracking {
          items {
            id
            createdAt
            updatedAt
            letter {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            status
            submittedDate
            deliveredDate
            registeredStatus
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        price
        priceht
        taxes
        receipt {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        sentAt
        template {
          templateId
          templateName
          requiredDocuments {
            name
            description
            __typename
          }
          hasValidatedRequiredDocuments
          recipientId
          __typename
        }
        addressHolder {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        addressHolderBackground {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        clearAddressHolderBackground
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      createdAt
      updatedAt
      date
      amount
      currency
      orderId
      type
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      basket {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          __typename
        }
        paymentDate
        version
        owner
        __typename
      }
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        date
        amount
        currency
        orderId
        type
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLetterTrackingStatus = /* GraphQL */ `
  query GetLetterTrackingStatus($id: ID!) {
    getLetterTrackingStatus(id: $id) {
      id
      createdAt
      updatedAt
      letter {
        createdAt
        updatedAt
        id
        recipient {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          recipientInContacts
          __typename
        }
        attachments {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        mailMode
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          hasGivenLREConsent
          recipientInContacts
          __typename
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        letterAccountId
        status
        transports {
          id
          reference
          __typename
        }
        trackingNumber
        tracking {
          items {
            id
            createdAt
            updatedAt
            letter {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            status
            submittedDate
            deliveredDate
            registeredStatus
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        price
        priceht
        taxes
        receipt {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        sentAt
        template {
          templateId
          templateName
          requiredDocuments {
            name
            description
            __typename
          }
          hasValidatedRequiredDocuments
          recipientId
          __typename
        }
        addressHolder {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        addressHolderBackground {
          contentLength
          fileName
          format
          pages
          content {
            bucket
            region
            key
            __typename
          }
          nature
          __typename
        }
        clearAddressHolderBackground
        version
        owner
        __typename
      }
      status
      submittedDate
      deliveredDate
      registeredStatus
      version
      owner
      __typename
    }
  }
`;
export const listLetterTrackingStatuss = /* GraphQL */ `
  query ListLetterTrackingStatuss(
    $filter: ModelLetterTrackingStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLetterTrackingStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        letter {
          createdAt
          updatedAt
          id
          recipient {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
            __typename
          }
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          mailMode
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          letterAccountId
          status
          transports {
            id
            reference
            __typename
          }
          trackingNumber
          tracking {
            items {
              id
              createdAt
              updatedAt
              status
              submittedDate
              deliveredDate
              registeredStatus
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          price
          priceht
          taxes
          receipt {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          sentAt
          template {
            templateId
            templateName
            requiredDocuments {
              name
              description
              __typename
            }
            hasValidatedRequiredDocuments
            recipientId
            __typename
          }
          addressHolder {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          addressHolderBackground {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          clearAddressHolderBackground
          version
          owner
          __typename
        }
        status
        submittedDate
        deliveredDate
        registeredStatus
        version
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      name
      phoneNumber
      email
      comment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phoneNumber
        email
        comment
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrepaidCode = /* GraphQL */ `
  query GetPrepaidCode($id: ID!) {
    getPrepaidCode(id: $id) {
      id
      useddate
      consumeddate
      createdAt
      updatedAt
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
          __typename
        }
        idDocument {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        kbis {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            letterAccountId
            status
            transports {
              id
              reference
              __typename
            }
            trackingNumber
            tracking {
              nextToken
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
              __typename
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            clearAddressHolderBackground
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        senderAddresses {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        basketCredits {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              __typename
            }
            idDocument {
              status
              __typename
            }
            kbis {
              status
              __typename
            }
            letters {
              nextToken
              __typename
            }
            contacts {
              nextToken
              __typename
            }
            senderAddresses {
              nextToken
              __typename
            }
            credits
            freeLetters
            payments {
              nextToken
              __typename
            }
            baskets {
              nextToken
              __typename
            }
            basketCredits {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            prepaidCodes {
              nextToken
              __typename
            }
            creditOrders {
              nextToken
              __typename
            }
            defaultAddressHolderBackground {
              status
              __typename
            }
            defaultSignature {
              status
              __typename
            }
            accountOptions {
              isMultipleSenderAddressesActivated
              __typename
            }
            version
            owner
            __typename
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            __typename
          }
          paymentDate
          version
          owner
          __typename
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            mailmodes
            owner
            __typename
          }
          nextToken
          __typename
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
              __typename
            }
            version
            owner
            __typename
          }
          nextToken
          __typename
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        defaultSignature {
          parts {
            bucket
            region
            key
            __typename
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
              __typename
            }
            nature
            __typename
          }
          __typename
        }
        accountOptions {
          isMultipleSenderAddressesActivated
          __typename
        }
        version
        owner
        __typename
      }
      mailmodes
      owner
      __typename
    }
  }
`;
export const listPrepaidCodes = /* GraphQL */ `
  query ListPrepaidCodes(
    $filter: ModelPrepaidCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrepaidCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        useddate
        consumeddate
        createdAt
        updatedAt
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            __typename
          }
          idDocument {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          kbis {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
              __typename
            }
            nextToken
            __typename
          }
          senderAddresses {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              owner
              __typename
            }
            nextToken
            __typename
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            nextToken
            __typename
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          basketCredits {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
              __typename
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
              __typename
            }
            basketCreditOrderAmounts {
              nextToken
              __typename
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
              __typename
            }
            paymentDate
            version
            owner
            __typename
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
              __typename
            }
            nextToken
            __typename
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
              __typename
            }
            nextToken
            __typename
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          defaultSignature {
            parts {
              bucket
              region
              key
              __typename
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
              __typename
            }
            __typename
          }
          accountOptions {
            isMultipleSenderAddressesActivated
            __typename
          }
          version
          owner
          __typename
        }
        mailmodes
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMailMode = /* GraphQL */ `
  query GetMailMode($id: ID!) {
    getMailMode(id: $id) {
      id
      name
      key
      desc
      price
      delay
      pageCost
      acknowledgment {
        cost
        price
        key
        __typename
      }
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMailModes = /* GraphQL */ `
  query ListMailModes(
    $filter: ModelMailModeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMailModes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        desc
        price
        delay
        pageCost
        acknowledgment {
          cost
          price
          key
          __typename
        }
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

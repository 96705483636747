import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown"
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight"

import clsx from "clsx"
import s from "./style.module.scss"

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const [showMenu, setShowMenu] = useState(false)
  const handleClick = (e) => {
    setShowMenu(!showMenu)
    onClick(e)
  }
  return (
    <div className={clsx("bg-light text-primary", s.customToggleWrapper)} ref={ref} onClick={handleClick}>
      {children}
      <FontAwesomeIcon className={s.icon} color="primary" icon={showMenu ? faChevronRight : faChevronDown} />
    </div>
  )
})

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const CustomMenu = React.forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
  return (
    <div ref={ref} style={style} className={clsx(className, s.dropDownMenu)} aria-labelledby={labeledBy}>
      <ul className="list-unstyled">{children}</ul>
    </div>
  )
})

import React, {useContext, useEffect, useState} from "react"
import API, {graphqlOperation} from "@aws-amplify/api"
import {getLetterPrice} from "../graphql/queries"
import {AuthContext} from "./AuthComponent"
import createPersistedState from 'use-persisted-state';
import {v4 as uuid} from "uuid"
import Storage from "@aws-amplify/storage";
import config from "../aws-exports"


const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config

const useGuestLetterId = createPersistedState("guestLetterId")

export const GuestLettersContext = React.createContext();

export const storeAttachments = async (filesWithPages, id) => {
  console.log(`storing temporary attachments for: ${id}`)
  const storeInputs = filesWithPages.map((fwp) => {
    const file = fwp.file
    const extension = file.name.split(".")[1]
    const { type: mimeType } = file
    const attachmentUid = uuid()
    const key = `temporary/${id}/attachments/${attachmentUid}.${extension}`
    return { ...fwp, key, mimeType }
  })

  for (const storeInput of storeInputs) {
    const { file, key, mimeType } = storeInput
    await Storage.put(key, file, {
      level: "public",
      contentType: mimeType,
    })
  }
  return storeInputs.map((storeInput) => {
    const pages = storeInput.numPages
    const { file, key, mimeType } = storeInput
    return {
      format: mimeType,
      fileName: file.name,
      contentLength: file.size,
      pages: pages,
      content: {
        bucket,
        key,
        region,
      },
    }
  })
}

const temporarySave = async (id, file, folder = "attachments") => {
  const extension = file.name.split(".")[1];
  const {type: mimeType} = file;
  const key = `temporary/${id}/${folder?`${folder}/`:""}${file.name}`
  await Storage.put(key, file, {
    level: "public",
    contentType: file.type,
  })
  const storeInput = {file, key, mimeType}
  const pages = storeInput.numPages;
  return {
    format: mimeType,
    fileName: file.name,
    contentLength: file.size,
    pages: pages,
    content: {
      bucket,
      key,
      region,
    }
  }
}

const readFromStorage = async (id, filename) => {
  const key = `temporary/${id}/${filename}`
  const url = await Storage.get(key,{level: 'public'})
  console.log(`read from storage with url built from key: ${key}`)
  const res = await fetch(url)
  if(res.ok){
    const data = await res.json()
    console.log("got file")
    console.log(data)
    if(data.template && !data.template.requiredDocuments) {
      data.template = null
    }

    if(data.attachments && !Object.prototype.toString.call(data.attachments) === '[object Array]' ){
      console.log("reset attachment after bug")
      data.attachments = []
    }
    return data
  }else{
    console.log("could not fetch url:")
    const text = await res.text()
    console.log(text)
    return null
  }

}

export const GuestLettersProvider = (props) => {
  console.log("guestletter component")
  const { registerWith } = useContext(AuthContext)
  const [guestLetterId, updateGuestLetterId] = useGuestLetterId(null)
  const [guestLetter, updateGuestLetterFromS3] = useState(null)
  const [isDirectSendPopinVisible, updateDirectSendPopinVisible] = useState(false)
  const [hasRegistered, updateHasRegistered] = useState(false)

  useEffect(()=>{
    console.log(`hasGuestLetter: ${guestLetter}`)
  },[guestLetter])
  useEffect(()=>{
    const initializeLetter = async () => {
      console.log(`useEffectGuestLetterId: ${guestLetterId}`)
      if(guestLetterId != null){
        const guestLetterFS = await readFromStorage(guestLetterId, "letter.json")
        updateGuestLetterFromS3(guestLetterFS)
      }
    }
    initializeLetter().then(()=>console.log("initializedLetter")).catch(e=>console.log("error"+e))
  },[guestLetterId])

  function ensureGuestLetterId() {
    if (guestLetterId == null) {
      console.log("guestLetterIdIsNull")
      const date = new Date()
      const anUuid = uuid();
      function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
      }
      const paddedDate = `${date.getFullYear()}${pad(date.getMonth()+1, 2)}${pad(date.getDate(), 2)}`
      const paddedHours = pad(date.getHours(), 2)
      const paddedMinutes = pad(date.getMinutes(),2)
      const paddedSeconds = pad(date.getSeconds(),2)
      const timeStamp = `${paddedDate}-${paddedHours}${paddedMinutes}${paddedSeconds}`
      const letterUid = `${timeStamp}-${anUuid}`
      console.log(`updateGuestLetterId: ${letterUid}`)
      updateGuestLetterId(letterUid)
      return letterUid
    }
    console.log(`guestLetterId: ${guestLetterId}`)
    return guestLetterId
  }

  const saveLetterUpdate = async (update, id = null) => {
    const letterId = id || ensureGuestLetterId();
    const letter = guestLetter || {}
    const updatedLetter = { id: letterId, ...letter, ...update}
    const content = JSON.stringify(updatedLetter)
    console.log(`letterToSave: ${content}`)
    const blob = new Blob([content], {type: "application/json"});
    const file =  new File([blob], `letter.json`, {
      type: "application/json",
      lastModified: new Date(),
    });
    await temporarySave(letterId, file, null)
    console.log(`savedLetter ${letterId} file: ${file}`)
    const letterFromStorage = await readFromStorage(letterId, 'letter.json')
    updateGuestLetterFromS3(letterFromStorage)
  }

  // const [s3Documents, updateS3DocumentsFromStorage] = useState({})

  const createGuestLetterFromTemplate = async (filesWithPages, aTemplate, aTemplateRecipient, letterElements) => {
    const hasFulfilledTemplateRequiredDocuments = false
    console.log(`filesWithPages: ${filesWithPages} t`)
    const id = ensureGuestLetterId()
    console.log(`id has been created: ${id}`)
    const storedAttachments = await storeAttachments(filesWithPages, id)
    console.log("storedAttachments: ")
    console.log(storedAttachments)
    // let attachments = (guestLetter && guestLetter.attachments) || null
    // if(attachments){
    //   attachments = [...attachments, ...storedAttachments]
    // }else{
    //   attachments = storedAttachments
    // }
    const attachments = storedAttachments
    let template = {}
    if(aTemplate){
      console.log("setting template")
      console.log(aTemplate)
      const tp = {
        templateId: aTemplate.id,
        templateName: aTemplate.name,
        requiredDocuments: aTemplate.mandatory_documents,
        hasValidatedRequiredDocuments:  hasFulfilledTemplateRequiredDocuments || aTemplate.mandatory_documents.length <= 0
      }
      if(aTemplateRecipient){
        tp.recipientId = aTemplateRecipient.id
      }
      template = tp
    }else{
      console.log("no associated template")
    }
    await saveLetterUpdate({...letterElements, attachments, template}, id)
    return guestLetter
  }

  const createDraft = async (filesWithPages, aTemplate, hasFulfilledTemplateRequiredDocuments, aTemplateRecipient) => {
    console.log(`filesWithPages: ${filesWithPages} t`)
    const id = ensureGuestLetterId()
    console.log(`id has been created: ${id}`)
    const storedAttachments = await storeAttachments(filesWithPages, id)
    console.log("storedAttachments: ")
    console.log(storedAttachments)
    let attachments = (guestLetter && guestLetter.attachments) || null
    if(attachments){
      attachments = [...attachments, ...storedAttachments]
    }else{
      attachments = storedAttachments
    }
    let template = {}
    if(aTemplate){
      console.log("setting template")
      console.log(aTemplate)
      const tp = {
        templateId: aTemplate.id,
        templateName: aTemplate.name,
        requiredDocuments: aTemplate.mandatory_documents,
        hasValidatedRequiredDocuments:  hasFulfilledTemplateRequiredDocuments || aTemplate.mandatory_documents.length <= 0
      }
      if(aTemplateRecipient){
        tp.recipientId = aTemplateRecipient.id
      }
      template = tp
    }else{
      console.log("no associated template")
    }
    await saveLetterUpdate({attachments, template}, id)
    return guestLetter
  }


  const editRecipient = async (letter) => {
    await saveLetterUpdate({recipient: letter.recipient})
    return guestLetter
  }

  const editMailMode = async (letter) => {
    await saveLetterUpdate({mailMode: letter.mailMode, recipient: letter.recipient})
    return guestLetter
  }

  const refreshCurrentLetterFromS3 = async ()=>{
    console.log("refreshing guest letter")
    const guestLetterId = ensureGuestLetterId()
    const guestLetterFS = await readFromStorage(guestLetterId, "letter.json")
    if(JSON.stringify(guestLetterFS) !== JSON.stringify(guestLetter)){
      console.log("hasChangedGuestLetter")
      updateGuestLetterFromS3(guestLetterFS)
      console.log(guestLetterFS)
      console.log(guestLetter)
    }else{
      console.log("guestLetterHasNotChanged")

    }

  }

  const removeAttachment = async (attachment) => {
    console.log(`attachment to remove: ${attachment}`)
    console.log(attachment)
    const remainingAttachments = guestLetter.attachments.filter((a) => {
      const itemName = a.fileName
      const attachmentToRemoveName = attachment.fileName
      const isEqual = itemName === attachmentToRemoveName
      console.log(`removing ${attachmentToRemoveName} ${itemName} ${isEqual}`)
      return !isEqual
    })
    console.log(remainingAttachments)
    let resetUpdate = {}
    if(remainingAttachments.length === 0){
      resetUpdate = {
        template: null,
        recipient: null,
        mailMode: null
      }
    } else if(guestLetter.template){
      console.log(`resetting template`)
      const template = guestLetter.template
      template.hasValidatedRequiredDocuments = false
      resetUpdate = {template: template}
    }
    await saveLetterUpdate({attachments : remainingAttachments, ...resetUpdate})
  }

  const addAttachments = async (filesWithPages, hasFulfilledRequiredDocuments) => {
    console.log("addAttachments")
    const id = ensureGuestLetterId()
    const fileWithPagesAsString = await storeAttachments(filesWithPages, id)
    const updatedAttachments = [...guestLetter.attachments, ...fileWithPagesAsString]
    const updatedTemplate = guestLetter.template
    if(updatedTemplate){
      updatedTemplate.hasValidatedRequiredDocuments = hasFulfilledRequiredDocuments
    }
    await saveLetterUpdate({attachments: updatedAttachments, template: updatedTemplate})
  }

  const createAddress = async (recipient) => {
    console.log("createAddress")
    await saveLetterUpdate({contact: recipient})
    return {id: "guestContactId"}
  }

  const cleanAfterLogin = () => {
    updateGuestLetterId(null)
    updateGuestLetterFromS3(null)
  }

  const updateSenderFromLetterEdition = async ({ sender }) => {
    console.log("sender to register:")
    console.log(sender)
    console.log("sender to register:")

    const letterId = ensureGuestLetterId()
   let senderUpdate = {}
    if (sender) {
      const { password, ...cleanedSender } =  sender
      senderUpdate = { ...cleanedSender }
    }

    const docUpdate = { sender: senderUpdate }
    console.log("updateSenderFromLetterEdition with")
    console.log(docUpdate)
    await updateS3Documents(docUpdate)
    await saveLetterUpdate(senderUpdate)
    if(sender){
      await registerWith(sender.email, sender.password)
      updateHasRegistered(true)
    }
  }
  const updateS3Documents = async (documentsUpdate) => {
    console.log("updateS3Documents")
    const letterId = ensureGuestLetterId();
    const letter = guestLetter || {}
    const updatedDocuments = {...documentsUpdate}
    const content = JSON.stringify(updatedDocuments)
    const blob = new Blob([content], {type: "application/json"});
    const file =  new File([blob], `s3Documents.json`, {
      type: "application/json",
      lastModified: new Date(),
    });
    await temporarySave(letterId, file, null)
    console.log(`savedLetter: ${letterId} file: ${file}`)
  }

  const editSender = async (letter) => {
    console.log("editSender")
    const { password, ...sender } = letter.sender
    await saveLetterUpdate({sender: sender})
  }

  async function getPrice(input) {
    console.log("going to get price for input:")
    console.log(input)
    const price = await API.graphql({ ...graphqlOperation(getLetterPrice, input), authMode: "API_KEY" })

    if(input.taxMode === "TTC"){
      const priceWithTax = parseFloat(JSON.parse(price.data.getLetterPrice).price)
      const taxAmount = parseFloat(JSON.parse(price.data.getLetterPrice).taxAmount)
      const priceWithoutTax = parseFloat(JSON.parse(price.data.getLetterPrice).priceWithoutTax)
      return {
        priceWithTax,
        taxAmount,
        priceWithoutTax
      }
    }else{
      const priceString = parseFloat(JSON.parse(price.data.getLetterPrice).priceWithoutTax)
      return priceString
    }
    console.log("has read price")

  }

  return (
    <GuestLettersContext.Provider
      value={{
        refreshCurrentLetterFromS3,
        currentLetter: guestLetter,
        createDraft,
        createGuestLetterFromTemplate,
        editRecipient,
        editMailMode,
        removeAttachment,
        addAttachments,
        getPrice,
        createAddress,
        cleanAfterLogin,
        contact: guestLetter && guestLetter.contact,
        sender: guestLetter && guestLetter.sender,
        updateSenderFromLetterEdition,
        editSender,
        isDirectSendPopinVisible,
        updateDirectSendPopinVisible,
        hasRegistered
      }}
    >
      {props.children}
    </GuestLettersContext.Provider>
  )
}
export const GuestLettersConsumer = GuestLettersContext.Consumer

export const withGuestLetters = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return (
        <GuestLettersConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</GuestLettersConsumer>
      )
    }
  }
  return ComponentWrapperWithAccountPropForChild
}

export class dataURLtoFile {
}
import React, {useContext, useEffect, useMemo, useState} from "react"
import API, { graphqlOperation } from "@aws-amplify/api"
import { createSenderAddress, updateSenderAddress, deleteSenderAddress } from "../graphql/mutations/SenderAddressMutations"
import {getSenderAddress} from "../graphql/queries"
import { onCreateSenderAddress, onDeleteSenderAddress, onUpdateSenderAddress } from "../graphql/subs/SenderAddressSubscriptions"
import { AccountContext } from "./AccountComponent"
import {getAccountWithoutLetters} from "../customQueries"

export const SenderAddressesContext = React.createContext()
/**
 * @return {null}
 */
export const SenderAddressProvider = (props) => {
    console.log("contact component")
    const { account } = useContext(AccountContext)
    const accountId = useMemo(()=>account && account.id, [account])
    const [pages, setPages] = useState([])
    const [pageIndex, setPageIndex] = useState(0)
    const [count, setCount] = useState(0)

    const hasNextPage = pages.length > pageIndex && pages[pageIndex].nextToken
    const currentContacts = pages.length > pageIndex ? pages[pageIndex].items : []

    console.log(`currentSenderAddress::: ${JSON.stringify(currentContacts[0])}`)

    const isActivated = useMemo(()=> account && account.accountOptions && account.accountOptions.isMultipleSenderAddressesActivated,[account])

    const countContacts = async () => {
        //This will not work if a user has more than 999 contacts
        //Dynamodb does not support count as of 12/2019
        //I'm sorry
        if (account) {
            const accountId = account.id
            const result = await API.graphql(graphqlOperation(getAccountWithoutLetters, { id: accountId, contactsLimit: 999 }))
            const newCount = result.data.getAccount.senderAddresses.items.length
            setCount(newCount)
            console.log(`senderAddressesCount: ${newCount}`)
        }

    }

    const fetchNextPageContacts = async (accountId, nextPageToken) => {
        const result = await API.graphql(
            graphqlOperation(getAccountWithoutLetters, { id: accountId, contactsNextToken: nextPageToken, contactsLimit: 5 })
        )
        const account = result.data.getAccount
        return account.senderAddresses
    }

    const browseNextPage = async () => {
        if (pages.length > pageIndex + 1) {
            setPageIndex(pageIndex + 1)
        } else {
            const nextPageToken = pages.length >= pageIndex ? pages[pageIndex].nextToken : null
            if (nextPageToken) {
                pages[pageIndex + 1] = await fetchNextPageContacts(account.id, nextPageToken)
                setPages(pages)
                setPageIndex(pageIndex + 1)
            }
        }
    }

    const browsePreviousPage = async () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    const refreshLastContacts = async () => {
        if (account) {
            await countContacts()
            const firstPage = await fetchNextPageContacts(account.id, null)
            firstPage.items.forEach((cntct) => {
                if(cntct.isProfessional){
                    cntct.label = `${cntct.companyName} - ${cntct.firstName} ${cntct.lastName}`
                }else{
                    cntct.label = `${cntct.firstName} ${cntct.lastName}`
                }
                cntct.recipientInContacts = cntct.id
            })
            setPages([firstPage])
            setPageIndex(0)
        }
    }

    useEffect(() => {
        if(accountId){
            refreshLastContacts()
                .then()
                .catch((e) => {
                    console.log("error during getAddresses")
                    console.log(e)
                })
            countContacts()
                .then()
                .catch((e) => {
                    console.log("error during countContacts")
                    console.log(e)
                })
        }
    }, [accountId])

    useEffect(() => {
        if (accountId) {
            console.log(`subscribing to letter creation/update/deletion for accountId: ${accountId}`)
            //subscribe to letters
            // Subscribe to creation of Todo
            const subscriptionCreateLetter = API.graphql(graphqlOperation(onCreateSenderAddress, { owner: accountId })).subscribe(
                {
                    next: (createData) => {
                        console.log("createData")
                        // console.log(createData);
                    },
                    error: (errorValue) => {
                        console.log(errorValue)
                    },
                    complete() {},
                }
            )
            const subscriptionUpdateLetter = API.graphql(graphqlOperation(onUpdateSenderAddress, { owner: accountId })).subscribe(
                {
                    next: (updateData) => {
                        console.log("updateData")
                        // console.log(updateData);
                    },
                    error: (errorValue) => {
                        console.log(errorValue)
                    },
                    complete() {},
                }
            )
            const subscriptionDeleteLetter = API.graphql(graphqlOperation(onDeleteSenderAddress, { owner: accountId })).subscribe(
                {
                    next: (createData) => {
                        console.log("deleteData")
                        // console.log(createData);
                    },
                    error: (errorValue) => {
                        console.log(errorValue)
                    },
                    complete() {},
                }
            )
            return () => {
                //We unsubscribe
                subscriptionCreateLetter.unsubscribe()
                subscriptionUpdateLetter.unsubscribe()
                subscriptionDeleteLetter.unsubscribe()
            }
        } else {
            console.log("no account - no subscriptions")
        }
    }, [accountId])

    const createAddress = async (formData) => {
        console.log("creating contact")
        console.log(formData)
        formData.recipientInContacts = undefined
        const inputData = { senderAddressAccountId: account.id, ...formData }
        const createAddresseeResult = await API.graphql(graphqlOperation(createSenderAddress, { input: inputData }))
        console.log(createAddresseeResult)
        await refreshLastContacts()
        return createAddresseeResult.data.createSenderAddress
    }

    const editAddress = async (formData) => {
        //We prepare the formData and remove fields that should not be included in the update
        console.log("editing contact")
        formData.owner = undefined
        formData.version = undefined
        formData.label = undefined
        formData.createdAt = undefined
        formData.updatedAt = undefined
        formData.account = undefined
        formData.id = formData.recipientInContacts
        formData.recipientInContacts = undefined
        const lastVersionContactResult = await API.graphql(graphqlOperation(getSenderAddress,{id: formData.id}))
        console.log(formData)
        console.log(lastVersionContactResult)
        const version = lastVersionContactResult.data.getSenderAddress.version
        formData.expectedVersion = version
        console.log(`formData: ${formData}`)
        await API.graphql(graphqlOperation(updateSenderAddress, { input: formData }))
        console.log("contact has been edited")
        await refreshLastContacts()
    }

    const removeContact = async (id) => {
        const formData = { id }
        await API.graphql(graphqlOperation(deleteSenderAddress, { input: formData }))
        await refreshLastContacts()
    }

    return (
        <SenderAddressesContext.Provider
            value={{
                count: count,
                isMultipleAddressesModeActivated: isActivated,
                editSenderAddress: editAddress,
                createSenderAddress: createAddress,
                removeContact: removeContact,
                browseNextPage: browseNextPage,
                browsePreviousPage: browsePreviousPage,
                hasNextPage: hasNextPage,
                pageIndex: pageIndex,
                senderAddresses: currentContacts,
            }}
        >
            {props.children}
        </SenderAddressesContext.Provider>
    )
}
export const SenderAddressesConsumer = SenderAddressesContext.Consumer

export const createBasket = /* GraphQL */ `
  mutation CreateBasket($input: CreateBasketInput!) {
    createBasket(input: $input) {
      createdAt
      updatedAt
      id
      status
      letters {
        items {
          createdAt
          updatedAt
          id
          recipient {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
          }
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          mailMode
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            defaultAddressHolderBackground {
              status
            }
            defaultSignature {
              status
            }
            version
            owner
          }
          letterAccountId
          status
          transports {
            id
            reference
          }
          trackingNumber
          tracking {
            items {
              id
              createdAt
              updatedAt
              status
              submittedDate
              deliveredDate
              registeredStatus
              version
              owner
            }
            nextToken
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          price
          priceht
          taxes
          receipt {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          sentAt
          template {
            templateId
            templateName
            requiredDocuments {
              name
              description
            }
            hasValidatedRequiredDocuments
            recipientId
          }
          addressHolder {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          addressHolderBackground {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          clearAddressHolderBackground
          version
          owner
        }
        nextToken
      }
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
        }
        idDocument {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        kbis {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          nextToken
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          nextToken
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            mailmodes
            owner
          }
          nextToken
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          nextToken
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        defaultSignature {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        version
        owner
      }
      basketCreditOrderAmounts {
        items {
          id
          createdAt
          updatedAt
          amount
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          creditOrder {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          version
          owner
        }
        nextToken
      }
      freeLettersSpent
      totalSpent
      priceOfStamps
      lastUpdate
      payment {
        id
        createdAt
        updatedAt
        date
        amount
        currency
        orderId
        type
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
          }
          idDocument {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          kbis {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
            }
            nextToken
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
            }
            nextToken
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            nextToken
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            nextToken
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
            }
            nextToken
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
            }
            nextToken
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          defaultSignature {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          version
          owner
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
            }
            nextToken
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            defaultAddressHolderBackground {
              status
            }
            defaultSignature {
              status
            }
            version
            owner
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
            }
            nextToken
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          paymentDate
          version
          owner
        }
      }
      paymentDate
      version
      owner
    }
  }
`;
export const updateBasket = /* GraphQL */ `
  mutation UpdateBasket($input: UpdateBasketInput!) {
    updateBasket(input: $input) {
      createdAt
      updatedAt
      id
      status
      letters {
        items {
          createdAt
          updatedAt
          id
          recipient {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
          }
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          mailMode
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            recipientInContacts
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            defaultAddressHolderBackground {
              status
            }
            defaultSignature {
              status
            }
            version
            owner
          }
          letterAccountId
          status
          transports {
            id
            reference
          }
          trackingNumber
          tracking {
            items {
              id
              createdAt
              updatedAt
              status
              submittedDate
              deliveredDate
              registeredStatus
              version
              owner
            }
            nextToken
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          price
          priceht
          taxes
          receipt {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          sentAt
          template {
            templateId
            templateName
            requiredDocuments {
              name
              description
            }
            hasValidatedRequiredDocuments
            recipientId
          }
          addressHolder {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          addressHolderBackground {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
          clearAddressHolderBackground
          version
          owner
        }
        nextToken
      }
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
        }
        idDocument {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        kbis {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          nextToken
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          nextToken
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            mailmodes
            owner
          }
          nextToken
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          nextToken
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        defaultSignature {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        version
        owner
      }
      basketCreditOrderAmounts {
        items {
          id
          createdAt
          updatedAt
          amount
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          creditOrder {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          version
          owner
        }
        nextToken
      }
      freeLettersSpent
      totalSpent
      priceOfStamps
      lastUpdate
      payment {
        id
        createdAt
        updatedAt
        date
        amount
        currency
        orderId
        type
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
          }
          idDocument {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          kbis {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
            }
            nextToken
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
            }
            nextToken
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            nextToken
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            nextToken
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
            }
            nextToken
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
            }
            nextToken
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          defaultSignature {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          version
          owner
        }
        basket {
          createdAt
          updatedAt
          id
          status
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
            }
            nextToken
          }
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            defaultAddressHolderBackground {
              status
            }
            defaultSignature {
              status
            }
            version
            owner
          }
          basketCreditOrderAmounts {
            items {
              id
              createdAt
              updatedAt
              amount
              version
              owner
            }
            nextToken
          }
          freeLettersSpent
          totalSpent
          priceOfStamps
          lastUpdate
          payment {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          paymentDate
          version
          owner
        }
      }
      paymentDate
      version
      owner
    }
  }
`;
export const createPaypalPayment = /* GraphQL */ `
  mutation CreatePaypalPayment($orderId: String!, $basketId: String!) {
    createPaypalPayment(orderId: $orderId, basketId: $basketId) {
      id
      createdAt
      updatedAt
      date
      amount
      currency
      orderId
      type
      account {
        createdAt
        updatedAt
        id
        username
        sender {
          formOfAddress
          firstName
          lastName
          isProfessional
          companyName
          addressPrefix
          address
          zipCode
          city
          email
          phoneNumber
          instructions
          phoneNumberLandline
        }
        idDocument {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        kbis {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        contacts {
          items {
            id
            createdAt
            updatedAt
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
            hasGivenLREConsent
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            owner
          }
          nextToken
        }
        credits
        freeLetters
        payments {
          items {
            id
            createdAt
            updatedAt
            date
            amount
            currency
            orderId
            type
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
          }
          nextToken
        }
        baskets {
          items {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
          nextToken
        }
        prepaidCodes {
          items {
            id
            useddate
            consumeddate
            createdAt
            updatedAt
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            mailmodes
            owner
          }
          nextToken
        }
        creditOrders {
          items {
            createdAt
            updatedAt
            id
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            showAsPurchaseOrder
            remainingCredits
            amountCreditOrderBaskets {
              nextToken
            }
            version
            owner
          }
          nextToken
        }
        defaultAddressHolderBackground {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        defaultSignature {
          parts {
            bucket
            region
            key
          }
          status
          attachments {
            contentLength
            fileName
            format
            pages
            content {
              bucket
              region
              key
            }
            nature
          }
        }
        version
        owner
      }
      basket {
        createdAt
        updatedAt
        id
        status
        letters {
          items {
            createdAt
            updatedAt
            id
            recipient {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
            mailMode
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              recipientInContacts
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            letterAccountId
            status
            transports {
              id
              reference
            }
            trackingNumber
            tracking {
              nextToken
            }
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            price
            priceht
            taxes
            receipt {
              contentLength
              fileName
              format
              pages
              nature
            }
            sentAt
            template {
              templateId
              templateName
              hasValidatedRequiredDocuments
              recipientId
            }
            addressHolder {
              contentLength
              fileName
              format
              pages
              nature
            }
            addressHolderBackground {
              contentLength
              fileName
              format
              pages
              nature
            }
            clearAddressHolderBackground
            version
            owner
          }
          nextToken
        }
        account {
          createdAt
          updatedAt
          id
          username
          sender {
            formOfAddress
            firstName
            lastName
            isProfessional
            companyName
            addressPrefix
            address
            zipCode
            city
            email
            phoneNumber
            instructions
            phoneNumberLandline
          }
          idDocument {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          kbis {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          letters {
            items {
              createdAt
              updatedAt
              id
              mailMode
              letterAccountId
              status
              trackingNumber
              price
              priceht
              taxes
              sentAt
              clearAddressHolderBackground
              version
              owner
            }
            nextToken
          }
          contacts {
            items {
              id
              createdAt
              updatedAt
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
              hasGivenLREConsent
              owner
            }
            nextToken
          }
          credits
          freeLetters
          payments {
            items {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            nextToken
          }
          baskets {
            items {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            nextToken
          }
          prepaidCodes {
            items {
              id
              useddate
              consumeddate
              createdAt
              updatedAt
              mailmodes
              owner
            }
            nextToken
          }
          creditOrders {
            items {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
            }
            nextToken
          }
          defaultAddressHolderBackground {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          defaultSignature {
            parts {
              bucket
              region
              key
            }
            status
            attachments {
              contentLength
              fileName
              format
              pages
              nature
            }
          }
          version
          owner
        }
        basketCreditOrderAmounts {
          items {
            id
            createdAt
            updatedAt
            amount
            basket {
              createdAt
              updatedAt
              id
              status
              freeLettersSpent
              totalSpent
              priceOfStamps
              lastUpdate
              paymentDate
              version
              owner
            }
            creditOrder {
              createdAt
              updatedAt
              id
              showAsPurchaseOrder
              remainingCredits
              version
              owner
            }
            version
            owner
          }
          nextToken
        }
        freeLettersSpent
        totalSpent
        priceOfStamps
        lastUpdate
        payment {
          id
          createdAt
          updatedAt
          date
          amount
          currency
          orderId
          type
          account {
            createdAt
            updatedAt
            id
            username
            sender {
              formOfAddress
              firstName
              lastName
              isProfessional
              companyName
              addressPrefix
              address
              zipCode
              city
              email
              phoneNumber
              instructions
              phoneNumberLandline
            }
            idDocument {
              status
            }
            kbis {
              status
            }
            letters {
              nextToken
            }
            contacts {
              nextToken
            }
            credits
            freeLetters
            payments {
              nextToken
            }
            baskets {
              nextToken
            }
            prepaidCodes {
              nextToken
            }
            creditOrders {
              nextToken
            }
            defaultAddressHolderBackground {
              status
            }
            defaultSignature {
              status
            }
            version
            owner
          }
          basket {
            createdAt
            updatedAt
            id
            status
            letters {
              nextToken
            }
            account {
              createdAt
              updatedAt
              id
              username
              credits
              freeLetters
              version
              owner
            }
            basketCreditOrderAmounts {
              nextToken
            }
            freeLettersSpent
            totalSpent
            priceOfStamps
            lastUpdate
            payment {
              id
              createdAt
              updatedAt
              date
              amount
              currency
              orderId
              type
            }
            paymentDate
            version
            owner
          }
        }
        paymentDate
        version
        owner
      }
    }
  }
`;
export const validatePromoCode = /* GraphQL */ `
  mutation ValidatePromoCode($code: String!) {
    validatePromoCode(code: $code)
  }
`;
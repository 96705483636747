import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import { Overlay, Spinner } from "react-bootstrap"

export const FullScreenLoaderContext = React.createContext({})
export const FullScreenLoaderProvider = ({ children }) => {
  const [messages, setMessages] = useState([])
  const [isDisplayingLastMessageDelay, setLastMessageWithDelay] = useState(null)
  const loadingMessage = messages.length > 0 ? messages[messages.length - 1] : isDisplayingLastMessageDelay


  function waitOneSec() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved")
      }, 600)
    })
  }
  useEffect(() => {
    if (isDisplayingLastMessageDelay) {
      console.log(`starting end transition for ${isDisplayingLastMessageDelay}`)
      let cancelled = false
      const bridge = async () => {
        await waitOneSec()
        if (!cancelled) {
          console.log(`finished end transition for ${isDisplayingLastMessageDelay}`)
          setLastMessageWithDelay(null)
        }
      }
      bridge()
        .then()
        .catch((e) => console.log(e))
      return () => {
        console.log(`cancelling end transition for ${isDisplayingLastMessageDelay}`)
        cancelled = true
      }
    }
  }, [isDisplayingLastMessageDelay])

  const addLoading = (message) => {
    setLastMessageWithDelay(null)
    console.log("add loading: " + message)
    const messagesWithoutDupe = messages.splice(messages.indexOf(message), 1)
    setMessages([...messagesWithoutDupe, message])
  }
  const removeMessage = async (message) => {
    console.log("remove loading: " + message)
    const updatedMessages = messages.splice(messages.indexOf(message), 1)
    if (updatedMessages.length === 0) {
      setLastMessageWithDelay(message)
      setMessages([])
    }
    setMessages(updatedMessages)
  }


  return (
    <FullScreenLoaderContext.Provider
      value={{
        addLoading,
        removeMessage,
        loadingMessage
      }}
    >
      {children}
    </FullScreenLoaderContext.Provider>
  )
}
export const FullScreenLoaderView= ({children})=>{
  const target = useRef(null)
  const {loadingMessage} = useContext(FullScreenLoaderContext)
  const style = useMemo(()=>loadingMessage ? { display: "none" } : {},[loadingMessage])
  const childrenToUse = {...children}
  childrenToUse.arrowProps = undefined

  return <>
    <div ref={target} style={style}>
      {childrenToUse}
    </div>
    <Overlay target={target.current} show={loadingMessage != null} placement="bottom">
      <div
          style={{ width: "100%", backgroundColor: "white", height: "100%", textAlign: "center", display: "flex" }}
      >
        <div style={{ margin: "auto" }}>
          <h2>{loadingMessage}</h2>
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    </Overlay>
  </>
}
/**
 * This is a hook that should be used in children views of the FullScreenLoaderProvider to show loading messages
 *
 * const setFullScreenLoader = useFullScreenLoader("chargement de votre compte")
 * setFullScreenLoader(true);
 * setFullScreenLoader(false);
 *
 * @param message
 * @returns {setLoading}
 */
export const useFullScreenLoader = (message) => {
  const { addLoading, removeMessage } = useContext(FullScreenLoaderContext)
  const [oldValue, saveValue] = useState(false)
  const setLoading = (value) => {
    console.log("setLoading: " + value + oldValue)
    if (value) {
      addLoading(message)
    } else {
      removeMessage(message)
    }
    saveValue(value)
  }

  useEffect(() => {
    //We do nothing at component loading
    //But we remove the loading message if the component is unmounted
    return () => {
      if (oldValue) {
        removeMessage(message)
      }
    }
  }, [])

  return { setLoading }
}

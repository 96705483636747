import React, {useContext, useEffect, useMemo, useState} from "react"
import {AccountContext} from "../components/AccountComponent"
import {BasketContext} from "../components/BasketComponent"
import {v4 as uuid} from "uuid"
import {
  apiCreateLetter,
  apiDeleteLetter,
  apiGetLetter,
  apiUpdateLetter, detectRecipient,
  fetchNextPageLetters,
  getPrice,
  storeAttachments,
  subscribeCreateLetter,
  subscribeDeleteLetter,
  subscribeUpdateLetter
} from "./LettersApi"
import {PaginationContext, withPagination} from "./PaginationComponent"

export const LettersContext = React.createContext()
/**
 * @return {null}
 */

const assertLetterIsEditable = (letter)=>{
  const status = letter.status
  if(
      status === "to_send"
      ||status === "basket"
      ||status === "deposit"
      ||status === "delivered"
      ||status === "returned"
      ||status === "failed"
  ){
    throw new Error(`Letter with id: ${letter.id} is not editable: ${status}`)
  }
}

export const LettersProvider = withPagination(fetchNextPageLetters, (props) => {


  console.log("letter component")
  const { account, user, isLoadingAccount } = useContext(AccountContext)
  const { refreshBasket, getFirstOrCreate } = useContext(BasketContext)
  const [lastLetters, setLastLetters] = useState([])
  const [currentLetter, setCurrentLetter] = useState()
  const [isLoadingLastLettersInternal, setLoadingLastLetters] = useState(false)
  const accountId = useMemo(()=>account? account.id : null, [account])
  const isLoadingLastLetters = useMemo(()=>(isLoadingLastLettersInternal || isLoadingAccount),[isLoadingLastLettersInternal, isLoadingAccount])
  const {
    invalidateFirstPage,
    currentPageItems,
    pageIndex,
    hasNextPage,
    browseNextPage,
    browsePreviousPage
  } = useContext(PaginationContext)

  const refreshLastLetters = async () => {
    console.log("refreshing last letters")
    if (account) {
      const firstPage = await fetchNextPageLetters(null, 5)
      setLastLetters(firstPage.items)
      invalidateFirstPage()
    }
  }


  useEffect(() => {
    if(accountId){
      let cancelled = false
      setLoadingLastLetters(true)
      refreshLastLetters()
        .then(() => setLoadingLastLetters(false))
        .catch((e) => {
          if (!cancelled) {
            setLoadingLastLetters(false)
          }
          console.error("error loading letters")
          console.error(e)
        })
      return () => {
        cancelled = true
      }
    }
  }, [accountId])

  useEffect(() =>
  {
    if(accountId){
      //subscribe to letters
      const subscriptionCreateLetter = subscribeCreateLetter(accountId, refreshLastLetters)
      const subscriptionUpdateLetter = subscribeUpdateLetter(accountId, refreshLastLetters)
      const subscriptionDeleteLetter = subscribeDeleteLetter(accountId, refreshLastLetters)
      return () => {
        //We unsubscribe
        subscriptionCreateLetter.unsubscribe()
        subscriptionUpdateLetter.unsubscribe()
        subscriptionDeleteLetter.unsubscribe()
      }
    }
  }, [accountId])

  const importGuestLetter = async (letter, contactId) => {
    console.log("importing letter created in guest mode")
    const letterUid = uuid()
    const fileWithPages = letter.attachments.map(a => ({
      file: a.file,
      numPages: a.pages
    }))
    console.log("FILEWITHPAGES ########################")
    console.log(fileWithPages)
    const attachmentInputs = await storeAttachments(fileWithPages, letterUid, user.username)
    console.log("ATTACHMENT_INPUTS")
    console.log(attachmentInputs)
    const inputData = {
      id: letterUid,
      letterAccountId: account.id,
      attachments: attachmentInputs,
      recipient: { ...letter.recipient, recipientInContacts: contactId},
      mailMode: letter.mailMode,
      sender: letter.sender,
      template: letter.template
    }
    console.log(inputData);
    const createdLetter = await apiCreateLetter(inputData)
    setCurrentLetter(createdLetter)
    await refreshLastLetters()
    return createdLetter
  }

  const createDraft = async (filesWithPages, template, hasValidatedRequiredDocuments, aTemplateRecipient) => {
    const letterUid = uuid()
    const attachmentInputs = await storeAttachments(filesWithPages, letterUid, user.username)
    const inputData = {
      id: letterUid,
      letterAccountId: account.id,
      attachments: attachmentInputs,
    }
    if(template){
      inputData.template = {
        templateId: template.id,
        templateName: template.name,
        requiredDocuments: template.mandatory_documents,
        hasValidatedRequiredDocuments: hasValidatedRequiredDocuments || template.mandatory_documents.length <= 0
      }
      if(aTemplateRecipient){
        inputData.template.recipientId = aTemplateRecipient.id
      }
    }
    console.log("has storedAttachements")
    const createdLetter = await apiCreateLetter(inputData)
    console.log("has createdLetter")
    setCurrentLetter(createdLetter)
    return createdLetter
  }

  const loadLetterWithId = async (id) => {
    const letter = await apiGetLetter(id)
    if (!letter) {
      throw `letter not found with id: ${id}`
    }
    setCurrentLetter(letter)
  }

  const refreshAndSetCurrentLetter = async (letter) => {
    if (letter) {
      const refreshedLetter = await apiGetLetter(letter.id)
      setCurrentLetter(refreshedLetter)
    } else {
      setCurrentLetter(null)
    }
  }

  const removeAttachment = async (attachment) => {
    console.log(`deleting attachments: ${attachment} from ${currentLetter.attachments}`)
    const remainingAttachments = currentLetter.attachments.filter((a) => a.content.key !== attachment.content.key)
    console.log(attachment)
    console.log(remainingAttachments)
    console.log("blip")
    const inputData = {
      id: currentLetter.id,
      attachments: remainingAttachments,
      expectedVersion: currentLetter.version,
    }
    if(currentLetter.template){
      currentLetter.template.hasValidatedRequiredDocuments = currentLetter.template.requiredDocuments.length <= 0
      inputData.template = currentLetter.template
    }
    assertLetterIsEditable(currentLetter)
    const updatedLetter = await apiUpdateLetter(inputData)
    setCurrentLetter(updatedLetter);
    console.log("updatedLetter")
    console.log(updatedLetter)
    await refreshLastLetters()
  }



  const addAttachments = async (filesWithPages, hasFulfilledRequiredDocuments) => {
    const letterUid = currentLetter.id
    const attachmentsInputs = await storeAttachments(filesWithPages, letterUid, user.username)
    //const guessedRecipients = await detectRecipient(filesWithPages[0].file)
    const inputData = {
      id: letterUid,
      attachments: [...currentLetter.attachments, ...attachmentsInputs],
      expectedVersion: currentLetter.version,
    }
    inputData.attachments.forEach(a => a["__typename"] = undefined)
    inputData.attachments.forEach(a => a.content["__typename"] = undefined)
    const template = currentLetter.template
    if(template){
      const updatedTemplate = currentLetter.template
      updatedTemplate.hasValidatedRequiredDocuments = hasFulfilledRequiredDocuments
      updatedTemplate["__typename"] = undefined
      if(updatedTemplate.requiredDocuments){
        updatedTemplate.requiredDocuments.forEach(reqd => reqd["__typename"] = undefined)
      }
      console.log("updatedTemplate")
      console.log(updatedTemplate)
      inputData.template = updatedTemplate
    }
    assertLetterIsEditable(currentLetter)
    const updatedLetter = await apiUpdateLetter(inputData);
    setCurrentLetter(updatedLetter);
  }

  const editRecipient = async (letter) => {
    const input = { id: letter.id, recipient: letter.recipient, expectedVersion: letter.version }
    assertLetterIsEditable(letter)
    const updatedLetter = await apiUpdateLetter(input);
    setCurrentLetter(updatedLetter);
  }

  const editMailMode = async (letter) => {
    letter.recipient["__typename"] = undefined
    const input = { id: letter.id, mailMode: letter.mailMode, recipient: letter.recipient, expectedVersion: letter.version }
    assertLetterIsEditable(letter)
    const updatedLetter = await apiUpdateLetter(input);
    setCurrentLetter(updatedLetter);
    return updatedLetter
  }

  const editSender = async (letter) => {
    letter.sender.id = undefined
    letter.sender.recipientInContacts = undefined
    const input = { id: letter.id, sender: letter.sender, expectedVersion: letter.version }
    assertLetterIsEditable(letter)
    const updatedLetter = await apiUpdateLetter(input);
    setCurrentLetter(updatedLetter);
    return updatedLetter
  }

  const storeAddressHolderFile = async (letter, addressHolderFile) => {
    if(currentLetter.id !== letter.id){
      throw Error("Simultaneous letter processing error")
    }
    const letterUid = letter.id
    let input
    console.log("saving: ")
    console.log(addressHolderFile)
    const addressHolderAttachments = await storeAttachments([{file: addressHolderFile, pages: 1}], letterUid, user.username)
    input = {
      addressHolder: addressHolderAttachments[0]
    }
    return input
  }

  const addToBasket = async (letter, addressHolder, addressHolderBackground, clearAddressHolderBackground) => {
    const currentBasket = await getFirstOrCreate()
    if (!currentBasket) {
      throw "No basket found after getFirstOrCreate"
    }
    let addressHolderInput = {}
    let addressHolderBackgroundInput = {}
    if(addressHolder){
       addressHolderInput = await storeAddressHolderFile(letter, addressHolder)
      if(addressHolderBackground){
        addressHolderBackgroundInput = await storeAddressHolderBackground(letter, addressHolderBackground)
      }else{
        addressHolderBackgroundInput = {
          addressHolderBackground : null,
          clearAddressHolderBackground: clearAddressHolderBackground
        }
      }
    }else{
      addressHolderInput = {
            addressHolderBackground: null,
            addressHolder: null,
            clearAddressHolderBackground: clearAddressHolderBackground
      }
    }
    console.log("addressHolderInput")
    console.log(addressHolderInput)
    console.log(addressHolderBackgroundInput)
    const input = {
      id: letter.id,
      status: "basket",
      expectedVersion: letter.version,
      letterBasketId: currentBasket.id,
      ...addressHolderInput,
      ...addressHolderBackgroundInput
    }
    console.log("inputUpdateLetterAddToBasket")
    console.log(input)
    assertLetterIsEditable(letter)
    const updatedLetter = await apiUpdateLetter(input);
    setCurrentLetter(updatedLetter);
    await refreshBasket()
  }

  const removeFromBasket = async (letter) => {
    if(letter.status !== "basket"){
      throw new Error("letter is not in basket")
    }
    const input = { id: letter.id, status: "draft", expectedVersion: letter.version, letterBasketId: null }
    const updatedLetter = await apiUpdateLetter(input);
    setCurrentLetter(updatedLetter);
    await refreshBasket()
  }

  const setLetterStatusToSend = async (letter) => {
    const input = { id: letter.id, status: "to_send", expectedVersion: letter.version }
    await apiUpdateLetter(input);
    await refreshLastLetters()
  }

  const deleteDraft = async (letter) => {
    assertLetterIsEditable(letter)
    await apiDeleteLetter(letter);
    setLoadingLastLetters(true);
    await refreshLastLetters();
    setLoadingLastLetters(false);
  }


  const storeAddressHolderBackground = async (letter, addressHolderBackgroundFile) => {
    const letterUid = letter.id
    if(letter.id !== currentLetter.id){
      throw Error("Simultaneous letter processing error")
    }
    const addressHolderAttachments = await storeAttachments([{file: addressHolderBackgroundFile, pages: 1}], letterUid, user.username)
    const input = { id: letter.id,
      addressHolderBackground: addressHolderAttachments[0],
      clearAddressHolderBackground: false
    }
    return input
  }

  return (
    <LettersContext.Provider
      value={{
        isLoadingLastLetters,
        letters: lastLetters,
        currentLetter: currentLetter,
        loadLetterWithId: loadLetterWithId,
        setCurrentLetter: refreshAndSetCurrentLetter,
        createDraft: createDraft,
        editRecipient,
        deleteDraft: deleteDraft,
        editMailMode: editMailMode,
        editSender: editSender,
        addToBasket: addToBasket,
        removeAttachment: removeAttachment,
        addAttachments,
        currentLetters: currentPageItems,
        hasNextPage: hasNextPage,
        browseNextPage: browseNextPage,
        browsePreviousPage: browsePreviousPage,
        pageIndex: pageIndex,
        removeFromBasket,
        getPrice,
        importGuestLetter,
        setLetterStatusToSend,
      }}
    >
      {props.children}
    </LettersContext.Provider>
  )
});

export const LettersConsumer = LettersContext.Consumer

export const withLetters = (Component) => {
  // Filter out extra props that are specific to this HOC and shouldn't be
  // passed through
  // const { filterProp, ...passThroughProps } = this.props;
  class ComponentWrapperWithAccountPropForChild extends React.Component {
    render() {
      const { ...passThroughProps } = this.props
      return <LettersConsumer>{(ownProps) => <Component {...ownProps} {...passThroughProps} />}</LettersConsumer>
    }
  }
  return ComponentWrapperWithAccountPropForChild
}

import { Nav, Navbar, Button } from "react-bootstrap"
import React, {useContext} from "react"
import { LinkContainer } from "react-router-bootstrap"
import "./sidenav.scss"
import { useHistory } from "react-router-dom"
import {AccountContext} from "./components/AccountComponent";

export const SideNav = ({ logout }) => {
  const history = useHistory()
  const goHome = () => {
    history.push("/")
  }

  return (
    <Navbar expand="lg" className="flex-column,fullHeight sideNav centering-sidenav">
      <Nav defaultActiveKey="/home" className="flex-column " style={{ marginTop: "100px", alignItems: "flex-center" }}>
        <LinkContainer to="/dashboard">
          <Nav.Link href="/dashboard" className="title-sidenav">
            Mon compte
          </Nav.Link>
        </LinkContainer>
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="flex-column sidenav"
          style={{ heigth: "100%", alignItems: "flex-start" }}
        >
          <LinkContainer to="/account">
            <Nav.Link href="/account">Mes informations</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/letters">
            <Nav.Link href="/account">Historique de mes envois</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/contacts">
            <Nav.Link href="/account">Mes contacts</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/adresses">
            <Nav.Link href="/account">Mes adresses d'expédition</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/purchases">
            <Nav.Link href="/purchases">Mes factures</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/credits">
            <Nav.Link href="/credits">Mes crédits</Nav.Link>
          </LinkContainer>
          <Button
            variant="outline-primary"
            style={{ marginTop: "100px", marginLeft: "8px" }}
            onClick={() => {
              logout()
              goHome()
            }}
          >
            Se déconnecter
          </Button>
        </Navbar.Collapse>
      </Nav>
    </Navbar>
  )
}

import {Button, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap"
import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye"
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons/faEyeSlash"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle"
import {AutoCompleteAddressForm, AutoCompleteCityForm, AutoCompleteZipCodeForm} from "./AutoCompleteZipCodeForm";

// import Option from "react-select/src/components/Option"

function checkZipCode(zipCode, errorOnUndefined) {
  const zipCodeRegex = /^\d{5}$|^\d{5}-\d{4}$/
  if (!zipCode) {
    if (errorOnUndefined) {
      return "Veuillez entrer un code postal valide"
    } else {
      return undefined
    }
  } else {
    return !zipCodeRegex.test(zipCode) ? "Veuillez entrer un code postal valide" : undefined
  }
}

function checkPhoneNumber(phoneNumber) {
  console.log(`phonenumeber: ${phoneNumber}`)
  if (phoneNumber && phoneNumber.length > 0) {
    const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/
    if (!phoneRegex.test(phoneNumber)) {
      return "Veuillez entrer un numéro de téléphone valide"
    }
  }
  return undefined
}
function checkEmail(email) {
  if (email && email.length > 0) {
    //https://emailregex.com/
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    if (!regex.test(email)) {
      return "Veuillez entrer un email valide"
    }
  }
}

function checkNoSpecial(value, fieldName, errorOnUndefined = true) {
  const specials = /[*|":<>[\]{}`\\()';@&$]/
  if (!value) {
    if (errorOnUndefined) {
      return `Veuillez entrer un ${fieldName} valide (min 2 caractères)`
    } else {
      return undefined
    }
  } else {
    if (specials.test(value)) {
      return "Les caractères spéciaux ne sont pas autorisés"
    } else {
      return undefined
    }
  }
}
export function checkNotEmpty(companyName, fieldName, errorOnUndefined = true, minChars = 2) {
  if (!companyName) {
    if (errorOnUndefined) {
      return `Veuillez entrer ${fieldName} (min 2 caractères)`
    } else {
      return undefined
    }
  } else if (companyName.length < minChars) {
    return `Veuillez entrer ${fieldName} (min ${minChars} caractères)`
  } else {
    return undefined
  }
}

function checkLengthFirstNameLastName(first, last, defaultLength = 38) {
  const concat = `${first} ${last}`
  if (!concat) {
    return undefined //We do not check the address field is defined here
  } else {
    if (concat.length > defaultLength) {
      return `Votre nom et prénom doivent comporter moins de ${defaultLength + 1} caractères`
    } else {
      return undefined
    }
  }
}
function checkLength(address, fieldName, defaultLength = 38) {
  if (!address) {
    return undefined //We do not check the address field is defined here
  } else {
    if (address.length > defaultLength) {
      return `Le champ ${fieldName} doit comporter moins de ${defaultLength + 1} caractères`
    } else {
      return undefined
    }
  }
}
export const validateSenderForLetterCreation = (addressForm, errorOnUndefined, shouldCheckEmail = false) =>{
  const nErrors = {}

  nErrors.firstName = checkNotEmpty(addressForm.firstName, "prenom", errorOnUndefined)
  nErrors.lastName = checkNotEmpty(addressForm.lastName, "nom", errorOnUndefined)

  if(!nErrors.firstName && !nErrors.lastName) {
    nErrors.firstName = checkNoSpecial(addressForm.firstName, "prenom", addressForm.isProfessional === false)
    nErrors.lastName = checkNoSpecial(addressForm.lastName, "nom", addressForm.isProfessional === false)
    if (!nErrors.firstName && !nErrors.lastName) {
      const error = checkLengthFirstNameLastName(addressForm.firstName, addressForm.lastName)
      nErrors.firstName = error
      nErrors.lastName = error
    }
  }

  nErrors.zipCode = checkZipCode(addressForm.zipCode, errorOnUndefined)
  nErrors.address = checkNotEmpty(addressForm.address, "une adresse", errorOnUndefined)
  if (!nErrors.address) {
    nErrors.address = checkLength(addressForm.address, "adresse")
  }
  nErrors.city = checkNotEmpty(addressForm.city, "une ville", errorOnUndefined)
  if (!nErrors.city) {
    nErrors.city = checkLength(addressForm.city, "ville", 32)
  }
  if(shouldCheckEmail){
    nErrors.email = checkNotEmpty(addressForm.email, "un email", errorOnUndefined)
    if(!nErrors.email){
      nErrors.email = checkEmail(addressForm.email)
    }
  }
  return nErrors
}
export const validateAll = (addressForm, errorOnUndefined = true, hasPassword = false, isSender = true) => {
  const nErrors = {}

  if(addressForm.isProfessional === false || isSender){
        nErrors.firstName = checkNotEmpty(addressForm.firstName, "prenom", errorOnUndefined)
        nErrors.lastName = checkNotEmpty(addressForm.lastName, "nom", errorOnUndefined)
  }
  if(!nErrors.firstName && !nErrors.lastName) {
      nErrors.firstName = checkNoSpecial(addressForm.firstName, "prenom", addressForm.isProfessional === false)
      nErrors.lastName = checkNoSpecial(addressForm.lastName, "nom", addressForm.isProfessional === false)
      if (!nErrors.firstName && !nErrors.lastName) {
          const error = checkLengthFirstNameLastName(addressForm.firstName, addressForm.lastName)
          nErrors.firstName = error
          nErrors.lastName = error
      }
  }
  if (errorOnUndefined && addressForm.isProfessional === undefined) {
    nErrors.isProfessional = "Veuillez indiquer si il s'agit d'un particulier ou d'un professionnel"
  }
  if (addressForm.isProfessional) {
    nErrors.companyName = checkNotEmpty(addressForm.companyName, "le nom de l'entreprise", errorOnUndefined)
    if (!nErrors.companyName) {
      nErrors.companyName = checkLength(addressForm.companyName, "nom de l'entreprise")
    }
  }

  nErrors.email = checkEmail(addressForm.email)
  nErrors.phoneNumber = checkPhoneNumber(addressForm.phoneNumber)
  nErrors.phoneNumberLandline = checkPhoneNumber(addressForm.phoneNumberLandline)
  nErrors.zipCode = checkZipCode(addressForm.zipCode, errorOnUndefined)
  nErrors.address = checkNotEmpty(addressForm.address, "une adresse", errorOnUndefined)
  if (!nErrors.address) {
    nErrors.address = checkLength(addressForm.address, "adresse")
  }
  nErrors.city = checkNotEmpty(addressForm.city, "une ville", errorOnUndefined)
  if (!nErrors.city) {
    nErrors.city = checkLength(addressForm.city, "ville", 32)
  }
  nErrors.addressPrefix = checkLength(addressForm.addressPrefix, "BATIMENT / RESIDENCE / LIEU-DIT")
  if (hasPassword) {
    if (!nErrors.email) {
      nErrors.email = checkNotEmpty(addressForm.email, "un email", errorOnUndefined)
    }
    nErrors.password = checkNotEmpty(addressForm.password, "un mot de passe", errorOnUndefined, 8)
  }
  return nErrors
}
export const validateLeadForm = (addressForm, errorOnUndefined) =>{
  const nErrors = {}
  nErrors.email = checkEmail(addressForm.email)
  nErrors.phoneNumber = checkPhoneNumber(addressForm.phoneNumber)
  if (!nErrors.phoneNumber) {
    nErrors.phoneNumber = checkNotEmpty(addressForm.phoneNumber, "un telephone", errorOnUndefined)
  }
  nErrors.name = checkNotEmpty(addressForm.name, "nom", errorOnUndefined)
  return nErrors
}
//This is a form that use two props: an address and an update state function.
export const ContactForm = ({
  addressForm,
  updateAddressForm,
  hideEmail,
  errors,
  includePassword = false,
  isSender = false,
  showLREConsentCheckbox = false
}) => {

  const sectionAutoComplete = "shipping"//`section-${isSender?"user":"contact"}`
  const [isPasswordVisible, updatePasswordVisibility] = useState(false)




  const handleChange = (evt) => {
    const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value
    // const nAddressForm = JSON.parse(JSON.stringify(addressForm))
    // nAddressForm[evt.target.name] = value;
    let updatedValue = value
    if (updatedValue === "") {
      updatedValue = undefined
    }
    updateAddressForm({ ...addressForm, [evt.target.name]: updatedValue })
  }



  const handleChangeCheckbox = (evt) => {
    const value = evt.target.value
    const isProfessional = value === "professional"
    let companyName
    if(isProfessional){
      companyName = addressForm.companyName
    }else{
      companyName = ""
    }
    updateAddressForm({ ...addressForm, isProfessional, companyName })
  }

  const setHasCollectedRecipientConsent = (value)=>{
    updateAddressForm({...addressForm, hasGivenLREConsent: value})
  }



  const proOrIndividualAndCompanyNameFields = ()=>{
      return <>
        <Form.Group controlId="addressOrganizationStatus">
          <Form.Label>STATUT</Form.Label>
          <Row>
            <Col md="6">
              <Form.Check custom type="radio">
                <Form.Check.Input
                  type="radio"
                  isInvalid={errors.isProfessional}
                  value="individual"
                  checked={addressForm.isProfessional === false}
                  onChange={handleChangeCheckbox}
                />
                <Form.Check.Label>Particulier</Form.Check.Label>
                <Form.Control.Feedback type="invalid">{errors.isProfessional}</Form.Control.Feedback>
              </Form.Check>
            </Col>
            <Col md="6">
              <Form.Check
                custom
                value="professional"
                type="radio"
                isInvalid={errors.isProfessional}
                onChange={handleChangeCheckbox}
                name="addressOrgStatut"
                label="Professionnel"
                id="radioStatusProfessional"
                checked={addressForm.isProfessional === true}
              />
            </Col>
          </Row>
        </Form.Group>
        {addressForm.isProfessional ? (
          <Form.Group controlId="addressOrganizationName">
            <Form.Label>NOM DE L'ENTREPRISE</Form.Label>
            <Form.Control
              name="companyName"
              autoComplete="organization"
              onChange={handleChange}
              isInvalid={errors.companyName}
              value={addressForm.companyName}
            />
            <Form.Control.Feedback type="invalid">{errors.companyName}</Form.Control.Feedback>
          </Form.Group>
        ) : null}
      </>
  }

  return (
    <Form key="addressForm">
      {!isSender && proOrIndividualAndCompanyNameFields()}
      <Row>
        <Col md="6">
          <Form.Group controlId="addressFirstName">
            <Form.Label>PRENOM</Form.Label>
            <Form.Control
              name="firstName"
              autoComplete={`${sectionAutoComplete} given-name`}
              onChange={handleChange}
              isInvalid={errors.firstName}
              value={addressForm.firstName}
            />
            <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group controlId="addressLastName">
            <Form.Label>NOM</Form.Label>
            <Form.Control
              name="lastName"
              onChange={handleChange}
              autoComplete={`${sectionAutoComplete} family-name`}
              isInvalid={errors.lastName}
              value={addressForm.lastName}
            />
            <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {isSender && proOrIndividualAndCompanyNameFields()}
      <Form.Group controlId="idAddressPrefix">
        <Form.Label>BATIMENT / RESIDENCE / LIEU-DIT</Form.Label>
        <Form.Control
          name="addressPrefix"
          autoComplete={`${sectionAutoComplete} address-line2`}
          onChange={handleChange}
          isInvalid={errors.addressPrefix}
          value={addressForm.addressPrefix}
        />
        <Form.Control.Feedback type="invalid">{errors.addressPrefix}</Form.Control.Feedback>
      </Form.Group>
      <AutoCompleteAddressForm addressForm={addressForm}
                               updateAddressForm={updateAddressForm}
                               errors={errors}
                               sectionAutoComplete={sectionAutoComplete} />
      <Row>
        <Col md="6">
          <AutoCompleteZipCodeForm addressForm={addressForm}
                                   updateAddressForm={updateAddressForm}
                                   errors={errors}
                                   sectionAutoComplete={sectionAutoComplete} />
        </Col>
        <Col md="6">
          <AutoCompleteCityForm addressForm={addressForm}
                                updateAddressForm={updateAddressForm}
                                errors={errors}
                                sectionAutoComplete={sectionAutoComplete} />
        </Col>
      </Row>
      {hideEmail ? null : (
        <Form.Group controlId="formdisabledEmail" data-testid="email">
          <Form.Label>EMAIL</Form.Label>
          <Form.Control
            type="email"
            name="email"
            autoComplete={`${sectionAutoComplete} email`}
            onChange={handleChange}
            isInvalid={errors.email}
            value={addressForm.email}
          />
          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Form.Group>
      )}
      {includePassword && (
        <Form.Group controlId="senderPassword">
          <Form.Label>Mot de passe*</Form.Label>
          <InputGroup className="">
            <Form.Control
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              autoComplete={`${sectionAutoComplete} new-password`}
              onChange={handleChange}
              isInvalid={errors.password}
              value={addressForm.password}
            />

            <InputGroup.Append>
              <Button
                variant="secondary"
                onClick={() => updatePasswordVisibility(!isPasswordVisible)}
                className="faEye"
              >
                <FontAwesomeIcon
                  icon={isPasswordVisible ? faEye : faEyeSlash}
                  style={{
                    marginTop: "5px",
                  }}
                />
              </Button>
            </InputGroup.Append>
            {errors.password ? <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> : null}
          </InputGroup>
        </Form.Group>
      )}

      <Row>
        <Col md="6">
          <Form.Group controlId="formdisabledEmail" data-testid="mobilePhone">
            <Form.Label>TELEPHONE PORTABLE</Form.Label>
            <Form.Control
              type="phone"
              name="phoneNumber"
              autoComplete={`${sectionAutoComplete} mobile tel-national`}
              onChange={handleChange}
              isInvalid={errors.phoneNumber}
              value={addressForm.phoneNumber}
            />
            <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group controlId="formdisabledEmail">
            <Form.Label>TELEPHONE FIXE</Form.Label>
            <Form.Control
              type="phone"
              name="phoneNumberLandline"
              autoComplete={`${sectionAutoComplete} home tel-national`}
              onChange={handleChange}
              isInvalid={errors.phoneNumberLandline}
              value={addressForm.phoneNumberLandline}
            />
            <Form.Control.Feedback type="invalid">{errors.phoneNumberLandline}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {showLREConsentCheckbox && !addressForm.isProfessional && <>
      <Form.Group controlId="formdisabledEmail">
      <Form.Check
        custom
        controlId
        id="recipientConsentCheckbox"
        value="hasRecipientConsent"
        name="recipientConsent"
        checked={addressForm.hasGivenLREConsent}
        onChange={(evt) => setHasCollectedRecipientConsent(evt.target.checked)}
        label="Accord de mon destinataire pour lui envoyer des recommandés en version électronique sur l'email renseigné (si non, le recommandé sera traité en version papier classique)*"
      />
      </Form.Group>
      <p style={{marginTop: "15px", textSize: "10pt"}}>*Votre destinataire étant un particulier, il vous faut recueillir son accord au préalable.
        Voir notre <a href='/md/Clause-d-accord-particulier-LRE.pdf' target='_blank'>modèle</a></p>
        </>
      }
    </Form>
  )
}

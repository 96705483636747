import React, {useContext, useMemo, Suspense} from "react"
import {ConnectedNavBar, DisconnectedNavBarBlue} from "../NavBar"
import {Route, Switch} from "react-router-dom"
import {AddresseeProvider} from "../components/ContactComponent"
import {PurchaseAndPaymentsProvider} from "../components/PurchasesAndPaymentsProvider"
import {Col, Row} from "react-bootstrap"
import {SideNav} from "../SideNav"
import {BasketProvider} from "../components/BasketComponent"
import {AccountProvider} from "../components/AccountComponent"
import {LettersProvider} from "../components/LetterComponent"
import {AppContentLayout} from "../components/layout/Layout"

import AppRoute from "./AppRoute"
import ConnectedMenuMobile from "../components/ui/Dropdown/ConnectedMenuMobile"

import {AuthContext} from "../components/AuthComponent"

import {LoginWall} from "../components/LoginWall"
import {StaticPages} from "./StaticPages"
import {GuestLetterImporter} from "../edition/GuestLetterImporter";
import {FullScreenLoaderView} from "../components/FullScreenLoader";

import PressPages from "./PressPages";
import {SenderAddressProvider} from "../components/SenderAddressesContext";

const LetterTemplates = React.lazy(()=>import("../templates/Templates"))
const ReactMark = React.lazy(()=>import("../components/Markdown"))
const AddressHolderBackgroundEdition = React.lazy(()=>import("../pages/AddressHolderBackgroundEdition"))
const DisconnectedHome = React.lazy(() => import("../DisconnectedHome"))
const LetterEdition = React.lazy(()=>import("../pages/LetterEdition"))
// const PressPages = React.lazy(() => import("./PressPages"))

const Letter = React.lazy(() => import("../pages/Letter"))
const Letters = React.lazy(() => import("../pages/Letters"))
const Basket = React.lazy(() => import("../pages/Basket"))
const Contacts = React.lazy(() => import("../Contacts"))
const MyAdresses = React.lazy(() => import("../pages/MyAddresses"))
const Dashboard = React.lazy(() => import("../Dashboard"))
const Account = React.lazy(() => import("../Account"))
const Credits = React.lazy(() => import("../Credits"))
const Purchases = React.lazy(() => import("../Purchases"))
const DocumentsEdition = React.lazy(() => import("../pages/DocumentsEdition"))
const SenderEdition = React.lazy(() => import("../pages/SenderEdition"))
const EmailAndPasswordEdition = React.lazy(() => import("../AccountEdition"))
const SimulatorPage = React.lazy(()=>import("../pages/SimulatorPage"))
const LetterTemplatePage = React.lazy(()=>import("../templates/LetterTemplatePage"))
const BodyTemplatePage = React.lazy(()=>import("../templates/BodyTemplatePage"))
const TemplateRecipientPage = React.lazy(()=>import( "../templates/TemplateRecipientPage"))
const TemplateRecipientEditionPage = React.lazy(()=>import( "../templates/TemplateRecipientEditionPage"))
const TemplateRecipientsPage = React.lazy(()=>import("../templates/TemplateRecipients"))
const TemplateNavigationPage = React.lazy(()=>import("../templates/TemplateNavigationPage"))

const SuspendableRoute = ({path, exact, children})=>{
  return <Route path={path} exact={exact}><Suspense fallback={<></>}>{children}</Suspense></Route>
}

const ConnectedAppRoutes = () => {
  const {user, logout} = useContext(AuthContext)
  const isGuest = useMemo(()=>!user, [user])
  return (
      <AccountProvider>
        <BasketProvider>
          <LettersProvider>
            <AddresseeProvider>
              <SenderAddressProvider>
                <GuestLetterImporter>
                  <FullScreenLoaderView>
                    {/*<Suspense fallback={<></>}>*/}
                    <Switch>
                      <AppRoute path="/" exact={true} component={DisconnectedHome} />
                      <Route path="*">
                        {user ? <ConnectedNavBar user={user} logout={logout} /> : <DisconnectedNavBarBlue/>}
                        <PurchaseAndPaymentsProvider>
                          {/*<Suspense fallback={<></>}>*/}
                          <Switch>
                            <AppRoute path="/simulateur" layout={AppContentLayout} component={SimulatorPage} componentProps={{isGuest}}/>
                            <AppRoute path="/modeles/" layout={AppContentLayout} component={TemplateNavigationPage} componentProps={{isGuest}}/>
                            <AppRoute path="/modele-lettre-recommandee/" layout={AppContentLayout} component={TemplateNavigationPage} componentProps={{isGuest}}/>
                            <AppRoute path="/templates/:id/body" layout={AppContentLayout} component={BodyTemplatePage}/>
                            <AppRoute path="/templates/letters" layout={AppContentLayout} component={LetterTemplates}/>
                            <AppRoute path="/templates/recipients/:id/edition" layout={AppContentLayout} component={TemplateRecipientEditionPage}/>
                            <AppRoute path="/templates/recipients/:id" layout={AppContentLayout} component={TemplateRecipientPage}/>
                            <AppRoute path="/templates/recipients" layout={AppContentLayout} component={TemplateRecipientsPage}/>
                            <AppRoute path="/templates/:id/letter" layout={AppContentLayout} component={LetterTemplatePage}/>
                            {PressPages.map((pp)=><AppRoute
                                key={pp.path}
                                path={pp.path}
                                layout={AppContentLayout}
                                component={ReactMark}
                                componentProps={{...pp}}
                            />)}
                            {StaticPages.map(sp=><AppRoute key={sp.path} path={sp.path} layout={AppContentLayout} component={ReactMark} componentProps={sp}/>)}
                            <AppRoute path="/createLetter" layout={AppContentLayout} component={LetterEdition} />
                            <LoginWall>
                              <Switch>
                                <AppRoute path="/letters/:id" layout={AppContentLayout} component={Letter} />
                                <AppRoute path="/basket" layout={AppContentLayout} component={Basket} />
                                <Route path="*">
                                  <ConnectedMenuMobile logout={logout} />
                                  <Row style={{ marginRight: "0px", marginLeft: "0px" }}>
                                    <Col
                                        md="3"
                                        style={{
                                          paddingLeft: "0",
                                          paddingRight: "0",
                                        }}
                                        className="sideNav d-none d-lg-block"
                                    >
                                      <SideNav logout={logout} />
                                    </Col>
                                    <Col
                                        lg="9"
                                        sm="12"
                                        xs="12"
                                        md="12"
                                        style={{
                                          paddingLeft: "0",
                                          paddingRight: "0",
                                          paddingBottom: "15px",
                                          paddingTop: "15px",
                                          minHeight: "calc(100vh - 82px)",
                                        }}
                                    >
                                      <SuspendableRoute path="/contacts" exact={true}>
                                        <Contacts />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/adresses" exact={true}>
                                        <MyAdresses />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/letters" exact={true}>
                                        <Letters />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/dashboard" exact={true}>
                                        <Dashboard />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/account">
                                        <Account />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/accountedit">
                                        <SenderEdition />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/acccountpasswordedit">
                                        <EmailAndPasswordEdition />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/credits">
                                        <Credits />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/purchases">
                                        <Purchases />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/identity_edit">
                                        <DocumentsEdition />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/edit_address_holder_background">
                                        <AddressHolderBackgroundEdition />
                                      </SuspendableRoute>
                                      <SuspendableRoute path="/edit_own_signature">
                                        {/*<SignatureEdition />*/}
                                      </SuspendableRoute>
                                    </Col>
                                  </Row>
                                </Route>
                              </Switch>
                            </LoginWall>
                          </Switch>
                          {/*</Suspense>*/}
                        </PurchaseAndPaymentsProvider>
                      </Route>
                    </Switch>
                    {/*</Suspense>*/}
                  </FullScreenLoaderView>
                </GuestLetterImporter>
              </SenderAddressProvider>
            </AddresseeProvider>
          </LettersProvider>
        </BasketProvider>
      </AccountProvider>
  )
}

export default ConnectedAppRoutes

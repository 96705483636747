import React from "react"
import Link from "react-router-dom/Link";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import fr from "date-fns/locale/fr";

export const longStatus = (letter) => {
  switch (letter.status) {
    case "deleted":
      return "Ce courrier a été supprimé par l'utilisateur"
    case "failed":
      return "Echec lors de l'envoi"
    case "basket":
      return <span>Ce courrier est dans <Link to="/basket"><a href="/basket">votre panier</a></Link>, il sera envoyé lorsque vous aurez réglé <Link to="/basket"><a href="/basket">votre panier</a></Link></span>
    case "to_send":
    case "sending":
    case "processing":
      return "Ce courrier est en cours d'envoi"
    case "sent":
      return "Ce courrier a bien été envoyé"
    case "delivered":
      return "Ce courrier a bien été réceptionné par votre destinataire"
    case "deposit":
      return "La lettre a été envoyée à l'impression et à la mise sous pli."
    case "not_enough_credits":
      return "Ce courrier n'a pas pu être envoyé car votre nombre de crédits est insuffisant"
    case "invalid_document":
      return `Ce courrier contient un document invalide et n'a pu être envoyé. Veuillez réessayer avec un autre document. Veuillez contacter contact@cliquezpostez.com avec l'identifiant ${letter.id} afin d'obtenir un remboursement.`
    case "draft":
    default:
      return "Ce courrier est en cours de création"
  }
}

export const letterStatus = (letter) => {
  const isJrmcLetter = (letter.mailMode !== "LRE-AR" && letter.mailMode !== "LRE")
  const submittedDate = ((letter.tracking && letter.tracking.items) || []).map(t => t.submittedDate).find(date => !!date)
  switch (letter.status) {
    case "failed":
      return "Erreur"
    case "basket":
      return "A envoyer"
    case "processing":
    case "to_send":
    case "sending":
      return "En cours d'envoi"
    case "sent":
      if(isJrmcLetter){
        return "En cours d'impression"
      }else{
        return "Envoyé"
      }
    case "deposit":
      if(isJrmcLetter){
        if(!submittedDate){
          return "Posté"
        }else{
          return `Posté le ${format(parseISO(submittedDate), "PP", {locale: fr})}` //2020-04-03T12:48:52+02:00
        }
      }else{
        return "Envoyé"
      }
    case "delivered":
      return "Distribué"
    case "not_enough_credits":
      return "Crédits insuffisants"
    case "deleted":
      return "Supprimé"
    case "invalid_document":
      return "Document invalide"
    case "draft":
    default:
      return "Brouillon"
  }
}

export function recipientTitle(recipient) {
  if (recipient) {
    if (recipient.isProfessional) {
      return recipient.companyName ? recipient.companyName : ""
    } else {
      return recipient.firstName && recipient.lastName ? `${recipient.firstName} ${recipient.lastName}` : ""
    }
  } else {
    return ""
  }
}

export function addressHolderRecipientTitle(recipient) {
  if (recipient) {
    if (recipient.isProfessional) {
      if((recipient.firstName && recipient.firstName.length > 0)||(recipient.lastName && recipient.lastName.length >0)){
        return `${recipient.firstName} ${recipient.lastName}\n${recipient.companyName}`
      }else{
        return recipient.companyName ? recipient.companyName : ""
      }
    } else {
      return recipient.firstName && recipient.lastName ? `${recipient.firstName} ${recipient.lastName}` : ""
    }
  } else {
    return ""
  }
}

export const letterRecipient = (letter) => {
  const recipient = letter.recipient
  return recipientTitle(recipient)
}
export const pageCountReducerForImpression = (documentPageCounts)=>{
  if(documentPageCounts.length === 1){
    //There is only one document. If it's only 1page, we send one page.
    // Otherwise we add a
    const documentPageCount = documentPageCounts[0]
    if(documentPageCount === 1){
      return 1
    }else{
      if(documentPageCount%2 === 0){
        return documentPageCount
      }else{
        return documentPageCount + 1
      }
    }
  }else{
    return documentPageCounts.reduce((acc, documentPageCount)=>{
      if(documentPageCount%2 === 0){
        return acc + documentPageCount
      }else{
        return acc + documentPageCount + 1
      }
    },0)
  }

}
export const maxTotalSizeMegs = 150 //LRE
export const maxPaperTotalSizeMegs = 50 //Eco LR
export const maxPaperSingleFileMegs = 20 //Eco LR
export const maxLRPages = 188 //LR
export const maxEcoPages = 80 //Eco LR
export const reducerSum = (accumulator, currentValue) => accumulator + currentValue

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  })
}
export function readFileAsyncAsDataUrl(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  })
}
import API, { graphqlOperation } from "@aws-amplify/api"
import { onCreateBasket, onUpdateBasket } from "../graphql/subs/BasketSubscriptions"
import { createBasket, updateBasket, validatePromoCode } from "../graphql/mutations/BasketMutations"
import {getLetterPrice, listBasketsSorted, getFormTokenForBasket, searchTemplateNavigation} from "../graphql/queries"
import {pageCountReducerForImpression} from "../utils/letters"

export async function apiLetterPrice(totalPages, mailMode) {
  const input = { pages: totalPages, mode: mailMode }
  await API.graphql({ ...graphqlOperation(getLetterPrice, input), authMode: "API_KEY" })
  const apiResult = await API.graphql({ ...graphqlOperation(getLetterPrice, input), authMode: "API_KEY" })
  console.log(apiResult.data.getLetterPrice)
  const price = JSON.parse(apiResult.data.getLetterPrice)
  return {
    total_price: parseFloat(price.price),
    ht_price: parseFloat(price.priceWithoutTax),
    taxes_price: parseFloat(price.taxAmount),
  }
}

//TODO move functions below to BasketApi.js
export const apiListLastBaskets = async (accountId) => {
  const result = await API.graphql(
    graphqlOperation(listBasketsSorted, {
      //filter: { status: { eq: "draft" } },
      status: { eq: "draft" },
      //owner: accountId,
    })
  )
  const baskets = result.data.listBasketsSorted
  return baskets.items
}
export const apiCreateNewBasket = async (accountId) => {
  const mutation = { input: { status: "draft", basketAccountId: accountId } }
  await API.graphql(graphqlOperation(createBasket, mutation))
}

export const apiCreditsNewBasket = async (accountId) => {
  const mutation = { input: { status: "credits", basketAccountId: accountId } }
  const result = await API.graphql(graphqlOperation(createBasket, mutation));

  return result;
}

export const apiCreditsGetBasket = async (accountId) => {
  const result = await API.graphql(
    graphqlOperation(listBasketsSorted, {
      status: { eq: "credits" },
    })
  )
  const baskets = result.data.listBasketsSorted
  return baskets.items[0]
}

export async function apiPromoCode(promoCode) {
  const input = { code: promoCode }
  const promoCodeResult = await API.graphql(graphqlOperation(validatePromoCode, input))
  return JSON.parse(promoCodeResult.data.validatePromoCode)
}

export function apiSubscribeCreateBasket(accountId, onNextBasket) {
  return API.graphql(graphqlOperation(onCreateBasket, { owner: accountId })).subscribe({
    next: (createData) => {
      const basket = createData.value.data.onCreateBasket
      onNextBasket(basket)
    },
    error(errorValue) {
      console.log("received an error listening to basket subscription")
      console.log(errorValue)
    },
    complete() {},
  })
}

export function apiSubscribeBasketUpdate(accountId, onNext) {
  return API.graphql(graphqlOperation(onUpdateBasket, { owner: accountId })).subscribe({
    next: (updateData) => {
      console.log("basket update notification")
      console.log(updateData)
      const basket = updateData.value.data.onUpdateBasket
      onNext(basket)
    },
    error(errorValue) {
      console.log(errorValue)
    },
    complete() {
      console.log("basket update completed... launching refresh")
    },
  })
}

export async function apiUpdateBasket(input, basket) {
  const input_full = { ...input, id: basket.id, expectedVersion: basket.version }
  await API.graphql(graphqlOperation(updateBasket, { input: input_full }))
}

async function serverPriceOfLetter(letter) {
  const mailMode = letter.mailMode
  const totalPages = pageCountReducerForImpression(letter.attachments.map((a) => a.pages))
  const price = await apiLetterPrice(totalPages, mailMode)
  return { price, letter }
}

export async function apiComputeBasketPrice(freeLetters, prepaidCodes, letters) {
  const freeLettersMailMode = ["ECO", "LRE", "LRE-AR"]
  const pricePromises = letters.map((letter) => serverPriceOfLetter(letter))
  const lettersAndPrices = await Promise.all(pricePromises)
  console.log("LETTERSANDPRICES")
  console.log(lettersAndPrices)
  const lettersWithOfferStatusAndPrice = lettersAndPrices.reduce(
    (acc, letterWithPrice) => {
      const { price, letter } = letterWithPrice
      const matchingCodes = acc.remainingPrepaidCodes.filter(code => {
          return code.mailmodes === letter.mailMode && (code.consumeddate === undefined || code.consumeddate === null)
      })

      if (acc.remainingFreeLetters > 0 && freeLettersMailMode.includes(letter.mailMode)) {
        return {
          remainingFreeLetters: acc.remainingFreeLetters - 1,
          remainingPrepaidCodes: acc.remainingPrepaidCodes,
          letterPrices: [
            ...acc.letterPrices,
            {
              letterId: letter.id,
              total_price: 0,
              ht_price: 0,
              taxes_price: 0,
              isFreeLetter: true,
            },
          ],
        }
      } else if (matchingCodes.length > 0) {
        const matchingCode = matchingCodes[0]
        return {
          remainingFreeLetters: acc.remainingFreeLetters,
          remainingPrepaidCodes: acc.remainingPrepaidCodes.filter(code => {
            return code.id !== matchingCode.id
          }),
          letterPrices: [
            ...acc.letterPrices,
            {
              letterId: letter.id,
              total_price: 0,
              ht_price: 0,
              taxes_price: 0,
              isFreeLetter: true,
            },
          ],
        }
      } else {
        return {
          remainingFreeLetters: acc.remainingFreeLetters,
          remainingPrepaidCodes: acc.remainingPrepaidCodes,
          letterPrices: [...acc.letterPrices, { ...price, letterId: letter.id, isFreeLetter: false }],
        }
      }
    },
    {
      remainingFreeLetters: freeLetters,
      remainingPrepaidCodes: prepaidCodes.items,
      letterPrices: [],
    }
  )
  console.log(lettersWithOfferStatusAndPrice)
  const basketPrice = lettersWithOfferStatusAndPrice.letterPrices.reduce(
    (acc, letterPrice) => ({
      totalPrice: acc.totalPrice + letterPrice.total_price,
      totalHtPrice: acc.totalHtPrice + letterPrice.ht_price,
      totalTaxesPrice: acc.totalTaxesPrice + letterPrice.taxes_price,
    }),
    { totalPrice: 0, totalHtPrice: 0, totalTaxesPrice: 0 }
  )
  const basketPriceTwoDigits = {
    total: Math.ceil(basketPrice.totalPrice * 100) / 100,
    totalWithoutTax: Math.ceil(basketPrice.totalHtPrice * 100) / 100,
    taxAmount: Math.ceil(basketPrice.totalTaxesPrice * 100) / 100,
  }
  const price = {
    ...basketPriceTwoDigits,
    remainingFreeLetters: lettersWithOfferStatusAndPrice.remainingFreeLetters,
    lettersPrices: lettersWithOfferStatusAndPrice.letterPrices.reduce((acc, letterPrice) => {
      console.log(letterPrice)
      acc[letterPrice.letterId] = { price: letterPrice.ht_price, isFreeLetter: letterPrice.isFreeLetter }
      return acc
    }, {}),
  }
  console.log("has computed price:")
  console.log(price)
  return price
}
export const apiFormTokenBasket = async(basketId, amount) => {
  console.log("basketId: ")
  console.log(basketId)
  const res = await API.graphql({ ...graphqlOperation(getFormTokenForBasket, {basketId, amount}), authMode: "API_KEY"})
  // const result = await API.graphql({
  //   ...graphqlOperation(searchTemplateNavigation, {
  //     //filter: {status: {neq: "deleted"}},
  //     //nextToken: nextToken,
  //     //limit: pageSize,
  //     query,
  //     scope
  //   }), authMode: "API_KEY" }
  // )
  console.log(res)
  console.log(res.data.getFormTokenForBasket)
  return res.data.getFormTokenForBasket
}
import React, {useContext, useEffect, useMemo, useState} from "react"
import {LettersContext} from "../components/LetterComponent"
import {ContactContext} from "../components/ContactComponent"
import {AccountContext} from "../components/AccountComponent"
import {dataURLtoFile, GuestLettersContext} from "../components/GuestLettersComponent"
import {useHistory} from "react-router-dom"
import {useFullScreenLoader} from "../components/FullScreenLoader"
import {Alert} from "react-bootstrap"
import Storage from "@aws-amplify/storage";
import {AuthContext} from "../components/AuthComponent";

const importFileFromTemporaryStorage = async (content) => {
  if(content){
    console.log("going to create file from content:")
    console.log(content)
    const url = await Storage.get(content.content.key,{level: 'public'})
    const res = await fetch(url)
    const blob = await res.blob()

    const file = new File([blob], content.fileName, {
      type: content.format,
      lastModified: new Date(),
      size: content.size,
    });
    console.log("file from content:")
    console.log(file)
    return file
  }
  return null
}

export const GuestLetterImporter = (props) => {
  const {logout } = useContext(AuthContext)
  const [init, setInit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const {importGuestLetter} = useContext(LettersContext)
  const {createAddress} = useContext(ContactContext)
  const {account, updateSenderFromLetterEdition} = useContext(AccountContext)
  const {currentLetter, contact, cleanAfterLogin, sender} = useContext(GuestLettersContext)
  const {children} = props
  const history = useHistory()
  const {setLoading: setLoadingFullScreen} = useFullScreenLoader("Import de votre brouillon")
  const accountId = useMemo(()=>account && account.id,[account])
  console.log("accountId: "+ accountId +" sender: "+sender + "currentLetter: "+currentLetter)

  useEffect(() => {
    let cancelled = false;
    const asyncImport = async () => {
      setLoadingFullScreen(true)
      console.log("launching import....")
      if (currentLetter && account) {
        setLoadingFullScreen(true);
        let contactId
        if (contact) {
          const newContact = await createAddress(contact);
          contactId = newContact.id
        }
        const promises = currentLetter.attachments.map(async (a)=>{
           const file = await importFileFromTemporaryStorage(a)
           a.file = file
        })
        await Promise.all(promises)
        const letter = await importGuestLetter(currentLetter, contactId)
        if (sender) {
          console.log("sender from guest letter")
          console.log(sender)
          const senderToImport =  {...sender, recipientInContacts: contactId}
          console.log("sender from guest letter")
          senderToImport.recipientInContacts = undefined
          await updateSenderFromLetterEdition({sender: senderToImport})
        }else{
          console.log("GUEST LETTER HAS NO SENDER")
        }
        cleanAfterLogin();
        if (!cancelled) {
          setLoadingFullScreen(false);
          history.push(`/letters/${letter.id}`)
        }
      }
    }

    if (currentLetter && accountId) {
      console.log("accountId..............................................")
      asyncImport().catch(e => {
        if (!cancelled) {
          setError("Une erreur s'est produite durant l'import de la lettre.");
          setLoadingFullScreen(false);
          logout()
        }
        console.log(e);
      });
    }
    setInit(false);
  }, [currentLetter, accountId, sender]);
  if (error) {
    return <Alert variant="danger">{error}</Alert>
  }
  if (init || loading) {
    return <Alert variant="info">Import de la lettre dans votre compte...</Alert>
  }
  return <>{children}</>
}
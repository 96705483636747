export const StaticPages = [
    {
        fileName: "services.md",
        title: "Lettre recommandée en ligne",
        description: "Nous vous accompagnons dans la digitalisation de vos lettres recommandées en ligne ainsi que dans les échanges avec vos contacts ou clients.",
        path: "/lettre-recommandee-en-ligne"
    }, {
        fileName: "about.md",
        title: "Service d'envoi de courrier en ligne",
        description: "Nous proposons un service d’envoi de courrier en ligne, simple à utiliser, sans abonnement, sans engagement et à des tarifs très avantageux.",
        path: "/a-propos"
    }, {
        fileName: "certifications.md",
        path: "/qualification-eidas",
        title: "Certifications eIDAS",
        description: "Valeur légale des recommandés électroniques"
    }, {
        path: "/cgv",
        fileName: "cgv.md",
        title: "Conditions Générales de Ventes (CGV)",
        description: "Consultez nos conditions générales de vente"
    }, {
        path: "/lettre-ecopli",
        fileName: "eco.md",
        title: "Lettre Ecopli",
        description: "Envoyez vos lettres écopli simplement et à tout moment depuis cliquezpostez.com : le mode d'envoi le plus économique de nos services."
    }, {
        path: "/lettre-suivie-en-ligne",
        fileName: "lettre-suivie.md",
        title: "Lettre suivie en ligne",
        description: "Vous souhaitez envoyer une lettre suivie en ligne ? Rien de plus simple, notre site CliquezPostez.com vous permet de le faire depuis chez soi."
    }, {
        path: "/faq",
        fileName: "faq.md",
        title: "LA FAQ",
        description: "Vous avez des questions : voici nos réponses aux questions les plus courantes"
    }, {
        path: "/lettre-recommandee-avec-ar",
        fileName: "lr.md",
        title: "La lettre recommandée avec ou sans AR",
        description: "Envoyez vos lettres recommandées avec AR ou sans : nos services d'envois de recommandés en ligne vous permettent de gérer tous vos courriers."
    }, {
        path: "/lettre-recommandee-electronique",
        fileName: "lre.md",
        title: "La Lettre Recommandée Électronique",
        description: "Envoyez en toute simplicité vos lettres recommandées électroniques directement depuis le site CliquezPostez.com : c'est rapide et économique."
    }, {
        path: "/mentions-legales",
        fileName: "mentions-legales.md",
        title: "Mentions Légales",
        description: "Mentions Légales du site CliquezPostez.com"
    }, {
        path: "/tarif-lettre-recommandee",
        fileName: "tarifs.md",
        title: "Tarif Lettre recommandée",
        description: "Réduisez votre budget courrier : faîtes vos calculs, nos tarifs pour des lettres recommandées ou courriers simples sont très attractifs"
    }, {
        path: "/checklist-demenagement",
        fileName: "checklist-demenagement.md",
        title: "Checklist Déménagement - Tout savoir pour déménager sereinement",
        description: "Un recommandé électronique offert pour tous les étudiants ! Retrouvez toutes nos astuces pour votre déménagement dans notre guide dédié gratuit."
    }, {
        path: "/mail-recommande",
        fileName: "mail-recommande.md",
        title: "L'envoi par mail recommandé",
        description: "Vous souhaitez envoyer un mail recommandé ? Grâce à CliquezPostez.com, envoyez votre recommandé en version électronique : c’est rapide, économique."
    },
    {
        path: "/lettre-resiliation-box/bouygues",
        fileName: "resiliation-box-bouygues.md",
        title: "Résilier Abonnement Bouygues",
        description: "Utilisez notre plateforme pour résilier votre abonnement Bouygues. Personnalisez l'une de nos lettres de résiliation et envoyez-la en ligne !"
    },
    {
        path: "/lettre-resiliation-bail",
        fileName: "modele-lettre-resiliation-bail.md",
        title: "Lettre résiliation bail",
        description: "Vous quittez votre logement et vous ne savez pas comment rédiger et envoyer votre lettre de résiliation bail ? Nous avons ce qu’il faut !"
    },
    {
        path: "/lettre-resiliation",
        fileName: "resiliation.md",
        title: "Lettre de résiliation",
        description: "Utilisez notre plateforme pour résilier tous vos contrats avec nos modèles de lettre de résiliation. Générez vos courriers et envoyez-les en LRE."
    },{
        path: "/lettre-resiliation-box",
        fileName: "resiliation-box.md",
        title: "Lettre de résiliation Internet",
        description: "Utilisez notre plateforme et générez vos lettres de résiliations box internet à envoyer en Recommandés Électroniques en quelques clics !"
    },{
        path: "/lettre-resiliation-mobile",
        fileName: "resiliation-mobile.md",
        title: "Lettre de résiliation forfait mobile",
        description: "Vous cherchez à résilier votre opérateur téléphonique ? Retrouvez tous nos modèles de lettres de résiliation forfait mobile sur notre site."
    },{
        path: "/lettre-resiliation-assurance",
        fileName: "resiliation-assurance.md",
        title: "Lettre de résiliation assurance",
        description: "Nous vous proposons une solution simple et rapide pour envoyer votre lettre de résiliation d’assurance directement en ligne."
    },{
        path: "/lettre-resiliation-sfr",
        fileName: "resiliation-sfr.md",
        title: "Lettre de résiliation SFR",
        description: "Vous êtes actuellement sous contrat ? Retrouvez tous nos modèles de lettres de résiliation pour SFR afin de résilier vos contrats rapidement."
    },{
        path: "/lettre-resiliation-deces",
        fileName: "resiliation-deces.md",
        title: "Lettre de résiliation assurance suite à un décès",
        description: "La résiliation d'une assurance suite à un décès peut être une étape difficile pour les proches du défunt, utilisez nos modèles de lettre."
    }

]
